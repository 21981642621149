import React from "react";
import "./summary.styles.css";
import moment from "moment";

import {
  Table,
  DatePicker,
  Modal,
  Spin,
  Drawer,
  Button,
  InputNumber,
  message,
  Popover,
  Badge,
  Checkbox,
  Empty,
  Alert,
  Progress,
} from "antd";

import API, { baseURL } from "../../api";

import {
  canUserEdit,
  isBeforeTwoDays,
  getVarietyMinPrice,
  getVarietyMaxPrice,
} from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setAuditModalVisibility } from "../../redux/summary/summary.actions";
import { selectIsModalVisible } from "../../redux/summary/summary.selectors";

import { selectAllVarietyInfo } from "../../redux/arrival-entry/arrival-entry.selectors";
import { setSummaryVisibility } from "../../redux/arrival-entry/arrival-entry.actions";
import { selectUserData } from "../../redux/user/user.selectors";

import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";

import CompetitorHoverTable from "../../components/competitor-hover-table/competitor-hover-table.component";

import Axios from "axios";

const FileDownLoad = require("js-file-download");
const { RangePicker } = DatePicker;

/**
 * @component
 * @description Reusable component to display table header.
 * @property {*} props
 * @memberof Summary
 */
const TableColumnHeader = (props) => {
  const { title, subtitle, marginTop, marginLeft } = props;

  return (
    <div
      style={{
        marginTop: marginTop,
        marginLeft: marginLeft,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          color: "white",
          fontSize: "12px",
          whiteSpace: "nowrap",
          textAlign: "left",
        }}
      >
        {title}
      </div>
      <div
        style={{
          color: "#cbdfff",
          fontSize: "11px",
          fontWeight: "normal",
          textAlign: "left",
        }}
      >
        {subtitle}
      </div>
    </div>
  );
};

//#region Modal Table Props
const modalTableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  scroll: { x: 150, y: 800 },
};
//#endregion

//#region Summary Table Props
const tableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  scroll: { x: 200, y: window.innerHeight - 240 },
};

//#endregion

/**
 *
 * @description Parent component to display arrival summary.
 * @component
 */
class Summary extends React.Component {
  state = {
    buyerType: "Mandi",
    selectedBranch: "",
    selectedRegion: "",
    selectedHub: "",
    selectedMandi: "",
    selectedVariety: "",
    dropdownData: {
      branches: [],
      regions: [],
      hubs: [],
      mandis: [],
      varieties: [],
    },
    defaultRegion: "Select Region",
    defaultHub: "Select Hub",
    defaultMandi: "Select Mandi",
    lastClicked: "",
    selectedBuyerType: "",
    selectedBranchVal: "",
    selectedRegionVal: "",
    selectedHubVal: "",
    selectedMandiVal: "",
    selectedVarietyVal: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    mandiTableData: [],
    mandiModalTableData: [],
    selectedRowKey: "",
    isSpinnerOnTable: true,
    isSpinnerOnModal: true,
    isSpinnerOn: false,
    isSpinnerOnProgress: false,
    ProgressClicked: false,
    drawerVisible: false,
    localPostItemList: { arrival_entries: [] },
    favMandis: [],
    favouriteChecked: false,
    isTableVisible: true,
    did: "",
  };

  /**
   * @function
   * @description Handle date filter selection.
   * @param {dateRange} dates
   * @param {string[]} dateStrings Array of strings consisting of selected date range.
   * @memberof Summary
   */
  onChange = (dates, dateStrings) => {
    this.setState(
      { startDate: dateStrings[0], endDate: dateStrings[1] },
      () => {
        let start = moment(this.state.startDate);
        let end = moment(this.state.endDate);
        let differenceInDays = end.diff(start, "days");
        this.setState({ differenceofDays: differenceInDays });
        if (differenceInDays > 7 && differenceInDays <= 180) {
          this.setState({ isTableVisible: false });
          return;
        } else if (differenceInDays > 180) {
          this.setState({ isTableVisible: false });
        } else {
          this.setState({ isTableVisible: true });
        }

        this.GetTableDataSummary(
          this.state.startDate,
          this.state.endDate,
          this.state.buyerType,
          this.state.selectedBranch,
          this.state.selectedRegion,
          this.state.selectedHub,
          this.state.selectedMandi,
          this.state.selectedVariety
        );
        //Get favourite mandi
        this.GetTableDataSummary(
          this.state.startDate,
          this.state.endDate,
          this.state.buyerType,
          this.state.selectedBranch,
          null,
          null,
          null,
          this.state.selectedVariety,
          true
        );
      }
    );
  };

  /**
   * @function
   * @description Shows bottom snackbar on modal price edit.
   * @memberof Summary
   */
  setDrawerVisible = () => {
    this.setState({ drawerVisible: true });
  };

  /**
   *
   * @function
   * @description Close drawer on successful update of modal price.
   * @memberof Summary
   */
  setDrawerClose = () => {
    const postHeaders = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    if (this.state.localPostItemList.arrival_entries.length !== 0) {
      this.setState({ isSpinnerOn: true });
      //implement post on submit
      API.post("/arrivals", this.state.localPostItemList, {
        headers: postHeaders,
      })
        .then((response) => {
          if (response.data.status) {
            message.success("Data saved !", 2.5);
            let data = this.state.mandiTableData;
            let dd;
            this.state.localPostItemList.arrival_entries.map((item) => {
              dd = data.map((dataItem) => {
                if (dataItem.key === item.key) {
                  dataItem.rate = item.price;
                }
                return dataItem;
              });
            });
            this.setState({
              drawerVisible: false,
              isSpinnerOn: false,
              localPostItemList: { arrival_entries: [] },
              mandiTableData: dd,
            });
          } else {
            message.error("Please try again");
            this.setState({ isEnable: true });
            this.setState({ isSpinnerOn: false });
          }
        })
        .catch((err) => {
          message.error("Please try again");
          this.setState({ isEnable: true });
          this.setState({ isSpinnerOn: false });
        });
    } else {
      this.setState({
        drawerVisible: false,
        isSpinnerOn: false,
      });
    }
  };

  /**
   * @function
   * @description Set price type in post modal price data on updation according to the buyer type.
   * @param {string} val Buyer type string.
   * @memberof Summary
   */
  getPriceType = (val) => {
    switch (val) {
      case "Mandi":
        return "Modal Price";
      case "FOR":
        return "Landing Cost";
      case "Farmer":
        return "Naked Grain Price";
      default:
        return;
    }
  };

  tempList = [];

  /**
   *
   * @function
   * @description Update tempList array which is used to store multiple data on modal price update.
   * @param {string} value Input field value.
   * @param {Object} rowRecord Particular row data.
   * @memberof Summary
   */
  setSendSummaryData = (value, rowRecord) => {
    if (this.state.localPostItemList.arrival_entries.length !== 0)
      this.setDrawerVisible();
    this.tempList = this.state.localPostItemList.arrival_entries;
    if (parseInt(value) === parseInt(rowRecord.rate) || value === "") {
      const modifiedList = this.tempList
        .map((item) => {
          if (item.key !== rowRecord.key) return item;
        })
        .filter((item) => item !== undefined);

      this.tempList = modifiedList;
      this.setState(
        {
          localPostItemList: { arrival_entries: modifiedList },
        },
        () => {
          if (this.state.localPostItemList.arrival_entries.length === 0)
            this.setDrawerClose();
        }
      );
      return;
    } else {
      for (let listCount = 0; listCount < this.tempList.length; listCount++) {
        if (this.tempList[listCount].key === rowRecord.key) {
          this.tempList[listCount].price = parseInt(value);
          this.setState({
            localPostItemList: { arrival_entries: this.tempList },
          });
          return;
        }
      }

      const postData = {
        key: rowRecord.key,
        arrival_date: rowRecord.date, //moment(rowRecord.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
        branch_id: parseInt(rowRecord.branchId),
        region_id: parseInt(rowRecord.regionId),
        hub_id: parseInt(rowRecord.hubId),
        mandi_id: parseInt(rowRecord.mandiId),
        arrival_type: this.state.buyerType,
        variety_id: parseInt(rowRecord.varietyId),
        price_type: this.getPriceType(this.state.buyerType),
        price: parseInt(value),
        min_price: parseInt(rowRecord.minPrice),
        max_price: parseInt(rowRecord.maxPrice),
        arrivals_quantity: parseInt(rowRecord.quantity),
        quality: rowRecord.quality,
        good_quality: parseFloat(rowRecord.good),
        average_quality: parseFloat(rowRecord.average),
        poor_quality: parseFloat(rowRecord.poor),
        crop_arrival_id: rowRecord.crop_arrival_id,
        remark: rowRecord.remarks,
        competitor: rowRecord.competitors.map((item) => {
          return {
            competitor_bid_id: item.competitor_bid_id,
            comp_max_price: item.competitor_max_price,
            comp_min_price: item.competitor_min_price,
            comp_name: item.competitor_name,
            purchase_plan: item.purchase_plan,
          };
        }),
      };

      this.tempList.push(postData);
      this.setState(
        { localPostItemList: { arrival_entries: this.tempList } },
        () => {
          this.setDrawerVisible();
        }
      );
    }
  };

  componentDidMount() {
    //first initialization branch details
    //#region

    const params = {
      table_type: "transaction",
    };
    API.get("/branches", { params: params }).then((response) => {
      API.get("/varieties").then((varietyResponse) => {
        this.setState({
          dropdownData: {
            branches: response.data.data.branches,
            regions: null,
            hubs: null,
            mandis: null,
            varieties: varietyResponse.data.data,
          },
          isSpinnerOnTable: false,
        });
      });

      this.GetTableDataSummary(
        this.state.startDate !== ""
          ? this.state.startDate
          : moment().format("YYYY-MM-DD"),
        this.state.endDate !== ""
          ? this.state.endDate
          : moment().format("YYYY-MM-DD"),
        this.state.buyerType
        // "farmer"
      );

      //For table data
    });
    //#endregion
  }

  //#region
  /**
   * @function
   * @description Retieve and store table data from API.
   * @param {string} startDate Start date of date range
   * @param {string} endDate End date of date range
   * @param {string} arrivalType Arrival type (Mandi/FOR)
   * @param {string} branchId Branch ID
   * @param {string} regionId Region ID
   * @param {string} hubId Hub ID
   * @param {string} mandiId Mandi ID
   * @param {string} varietyId Variety ID
   * @param {boolean} favourite True if favourite is checked
   * @memberof Summary
   */
  GetTableDataSummary(
    startDate,
    endDate,
    arrivalType,
    branchId,
    regionId,
    hubId,
    mandiId,
    varietyId,
    favourite
  ) {
    this.setState({ isSpinnerOnTable: true });
    const tableParams = {
      start_date: startDate,
      end_date: endDate,
      arrival_type: arrivalType,
      branch_id: branchId === "" ? null : branchId,
      hub_id: hubId === "" ? null : hubId,
      mandi_id: mandiId === "" ? null : mandiId,
      variety_id: varietyId === "" ? null : varietyId,
      favourite: arrivalType === "Mandi" ? favourite : null,
    };

    API.get("/audits", { params: tableParams })
      .then((response) => {
        if (response.data.data && response.data.data !== "") {
          const dd =
            response.data.data.audits &&
            response.data.data.audits.map((item) => {
              return {
                key: item.crop_arrival_id + "_" + this.state.buyerType,
                crop_arrival_id: item.crop_arrival_id,
                isMainMandi: item.is_main_mandi,
                date: item.arrival_date,
                branch: item.branch_name,
                branchId: item.branch_id,
                region: item.region_name,
                regionId: item.region_id,
                hub: item.hub_name,
                hubId: item.hub_id,
                mandiOrFor: arrivalType, //mandi or FOR or Farmer
                mandi: item.mandi_name,
                mandiId: item.mandi_id,
                variety: item.variety_name,
                varietyId: item.variety_id,
                arrivalTypeId: item.arrival_type_id,
                quantity: item.quantity,
                rate: item.price,
                trend: "5",
                minPrice: item.min_price,
                maxPrice: item.max_price,
                quality: item.quality,
                good: item.good_quality,
                average: item.average_quality,
                poor: item.poor_quality,
                status: item.territory_status,
                remarks: item.remark,
                compPrice:
                  item.competitor_max_price && item.competitor_min_price
                    ? parseInt(
                        (item.competitor_max_price +
                          item.competitor_min_price) /
                          2
                      )
                    : null,

                competitors: item.competitor_bids.map((competitor) => {
                  return {
                    key: competitor.competitor_bid_id,
                    competitor_bid_id: competitor.competitor_bid_id,
                    competitor_name: competitor.competitor_name,
                    competitor_min_price: competitor.competitor_min_price,
                    competitor_max_price: competitor.competitor_max_price,
                    purchase_plan: competitor.purchase_plan,
                  };
                }),
                favMandi: favourite,
              };
            });
          if (favourite) {
            this.setState({ favMandis: dd, isSpinnerOnTable: false });
          } else {
            this.setState({ mandiTableData: dd, isSpinnerOnTable: false });
          }
        } else {
          if (favourite) {
            this.setState({ favMandis: [], isSpinnerOnTable: false });
          } else {
            this.setState({ mandiTableData: [], isSpinnerOnTable: false });
          }
        }
      })
      .catch((err) => {
        this.setState({ isSpinnerOnTable: false });
      });
  }
  //#endregion

  //#region
  /**
   *
   * @function
   * @description Retieve and store table data for audit trails in modal from API.
   * @param {string} clickedSummaryId
   * @memberof Summary
   */
  GetModalTableDataSummary(clickedSummaryId) {
    this.setState({ isSpinnerOnModal: true });

    API.get(`/audits/${clickedSummaryId}?arrival_type=${this.state.buyerType}`)
      .then((response) => {
        if (
          response.data.data &&
          response.data.data !== "" &&
          response.data.status
        ) {
          const dd =
            response.data.data &&
            response.data.data.audits.map((item) => {
              // console.log("Single item : ", item);
              return {
                key: item.crop_arrival_id + "_" + this.state.buyerType,
                crop_arrival_id: item.crop_arrival_id,
                datetime: item.modified_time,
                rate: item.price,
                costType: item.price_type,
                minPrice: item.min_price,
                maxPrice: item.max_price,
                quantity: item.quantity,
                quality: item.quality,
                good: item.good_quality,
                average: item.average_quality,
                poor: item.poor_quality,
                modified_by: item.modified_by,
                modified_by_name: item.name,
                isActive: item.is_active ? "Entered" : "Deleted",
                status: "Open",
                remarks: item.remark,
                competitors: item.competitor_bids.map((competitor) => {
                  return {
                    key: competitor.competitor_bid_id,
                    competitor_bid_id: competitor.competitor_bid_id,
                    competitor_name: competitor.competitor_name,
                    competitor_min_price: competitor.competitor_min_price,
                    competitor_max_price: competitor.competitor_max_price,
                    purchase_plan: competitor.purchase_plan,
                  };
                }),
              };
            });

          this.setState({ isSpinnerOnModal: false, mandiModalTableData: dd });
        } else {
          this.setState({ isSpinnerOnModal: false, mandiModalTableData: [] });
        }
      })
      .catch((err) => {
        this.setState({ isSpinnerOnModal: false, mandiModalTableData: [] });
      });
  }
  //#endregion

  render() {
    const { userData, isTab } = this.props;

    const user = userData;

    /**
     *
     * @description Toggle table data between default and favourites.
     * @memberof Summary
     */
    const setDataForTable = () => {
      if (this.state.favouriteChecked) return this.state.favMandis;

      return this.state.mandiTableData;
    };

    //type handling
    //#region
    /**
     *
     * @description Handle type filter selection.
     * @param {string} value Selected arrival type.
     * @memberof Summary
     */
    const handleTypeSelect = (value) => {
      // console.log("Selected type: ", value);
      this.setState(
        (prevState) => ({
          ...prevState,
          selectedBuyerType: value,
          buyerType: value,
          isSpinnerOnTable: true,
        }),
        () => {
          this.GetTableDataSummary(
            this.state.startDate !== ""
              ? this.state.startDate
              : moment().format("YYYY-MM-DD"),
            this.state.endDate !== ""
              ? this.state.endDate
              : moment().format("YYYY-MM-DD"),

            value,
            this.state.selectedBranch,
            this.state.selectedRegion,
            this.state.selectedHub,
            this.state.selectedMandi,
            this.state.selectedVariety
          );
          if (value === "Mandi") {
            this.GetTableDataSummary(
              this.state.startDate,
              this.state.endDate,
              value,
              this.state.selectedBranch,
              null,
              null,
              null,
              this.state.selectedVariety,
              true
            );
          }
        }
      );
    };
    //#endregion

    //variety handling
    //#region
    //filtering variety data to name and key
    let varietyId, varietyName;
    if (this.state.dropdownData.varieties) {
      //console.log(this.state.dropdownData.branches);
      varietyId = this.state.dropdownData.varieties.map(
        (item) => item.variety_id
      );
      varietyName = this.state.dropdownData.varieties.map(
        (item) => item.variety_name
      );
    }

    /**
     *
     * @description Handle variety filter selection.
     * @param {string} value Selected variety value.
     * @param {object} props Selected variety object (key and value).
     * @memberof Summary
     */
    const handleVarietySelect = (value, props) => {
      this.setState({ isSpinnerOnTable: true });
      // console.log("Selected version: ", value);

      this.setState(
        (prevState) => ({
          ...prevState,
          selectedVarietyVal: value,
          selectedVariety: value === "All" ? "" : props.key,
        }),
        () => {
          this.GetTableDataSummary(
            this.state.startDate,
            this.state.endDate,
            this.state.buyerType,
            this.state.selectedBranch,
            this.state.selectedRegion,
            this.state.selectedHub,
            this.state.selectedMandi,
            this.state.selectedVarietyVal === "All" ? null : props.key
          );
          //To get favourite mandis
          if (this.state.buyerType === "Mandi")
            this.GetTableDataSummary(
              this.state.startDate,
              this.state.endDate,
              this.state.buyerType,
              this.state.selectedBranch,
              null,
              null,
              null,
              this.state.selectedVarietyVal === "All" ? null : props.key,
              true
            );
        }
      );
    };
    //#endregion

    //branch handling
    //#region
    //filtering branch data to name and key
    let branchId, branchName;
    if (this.state.dropdownData.branches) {
      //console.log(this.state.dropdownData.branches);
      branchId = this.state.dropdownData.branches.map(
        (item) => item.territory_id
      );
      branchName = this.state.dropdownData.branches.map(
        (item) => item.territory_name
      );
    }

    /**
     *
     * @description Handle branch filter selection.
     * @param {string} value Selected branch value.
     * @param {object} props Selected branch object (key and value).
     * @memberof Summary
     */
    const handleBranchSelect = (value, props) => {
      this.setState({ isSpinnerOnTable: true });

      // API.get(`/branches/${props.key}/hubs/`, { headers: headers })
      API.get(
        `/hubs?table_type=transaction&mapping=primary&branch_id=${props.key}`,
        {}
      ).then((response) => {
        this.setState(
          (prevState) => ({
            ...prevState,
            selectedBranch: value === "All" ? "" : props.key,
            dropdownData: {
              ...prevState.dropdownData,
              // regions: value === "All" ? [] : response.data.data.regions,
              hubs: value === "All" ? [] : response.data.data.hubs,
              mandis: null,
            },
            lastClicked: value === "All" ? "BranchAll" : "Branch",
            selectedBranchVal: value,
            selectedRegionVal: this.state.defaultRegion,
            selectedHubVal: this.state.defaultHub,
            selectedMandiVal: this.state.defaultMandi,
            favouriteChecked:
              value === "All" ? false : prevState.favouriteChecked,
            // favMandis: dd,
          }),
          () => {
            this.GetTableDataSummary(
              this.state.startDate !== ""
                ? this.state.startDate
                : moment().format("YYYY-MM-DD"),
              this.state.endDate !== ""
                ? this.state.endDate
                : moment().format("YYYY-MM-DD"),

              this.state.buyerType,
              value === "All" ? "" : props.key,
              null,
              null,
              null,
              this.state.selectedVariety
            );
            //To get favourite mandis
            if (this.state.buyerType === "Mandi")
              if (value !== "All") {
                this.GetTableDataSummary(
                  this.state.startDate,
                  this.state.endDate,
                  this.state.buyerType,
                  value === "All" ? "" : props.key,
                  null,
                  null,
                  null,
                  this.state.selectedVariety,
                  true
                );
              }
          }
        );
      });
    };
    //#endregion

    //hub handling
    //#region
    //filtering hub data to name and key
    let hubId, hubName;
    if (this.state.dropdownData.hubs) {
      hubId = this.state.dropdownData.hubs.map((item) => item.territory_id);
      hubName = this.state.dropdownData.hubs.map((item) => item.territory_name);
    }

    /**
     *
     * @description Handle hub filter selection.
     * @param {string} value Selected hub value.
     * @param {object} props Selected hub object (key and value).
     * @memberof Summary
     */
    const handleHubSelect = (value, props) => {
      this.setState({ isSpinnerOnTable: true });

      const params = {
        table_type: "transaction",
        mapping: "primary",
        hub_id: props.key,
      };
      // API.get(`/hubs/${value === "All" ? "" : props.key + "/mandis/"}`, {
      API.get(`/mandis`, {
        params: params,
      }).then((response) => {
        this.setState(
          (prevState) => ({
            ...prevState,
            selectedHub: value === "All" ? "" : props.key,
            dropdownData: {
              ...prevState.dropdownData,
              mandis: value === "All" ? null : response.data.data.mandis,
            },
            lastClicked: value === "All" ? "HubAll" : "Hub",
            selectedHubVal: value,
            selectedMandiVal: this.state.defaultMandi,
          })
          // console.log("Hub got : ", this.state)
        );
      });
      this.GetTableDataSummary(
        this.state.startDate !== ""
          ? this.state.startDate
          : moment().format("YYYY-MM-DD"),
        this.state.endDate !== ""
          ? this.state.endDate
          : moment().format("YYYY-MM-DD"),

        this.state.buyerType,
        this.state.selectedBranch,
        this.state.selectedRegion,
        value === "All" ? null : props.key,
        null,
        this.state.selectedVariety
      );
    };
    //#endregion

    //mandi handling
    //#region
    //filtering mandi data to name and key
    let mandiId, mandiName;
    if (this.state.dropdownData.mandis) {
      mandiId = this.state.dropdownData.mandis.map((item) => item.mandi_id);
      mandiName = this.state.dropdownData.mandis.map((item) => item.mandi_name);
    }

    /**
     *
     * @description Handle mandi filter selection.
     * @param {string} value Selected mandi value.
     * @param {object} props Selected mandi object (key and value).
     * @memberof Summary
     */
    const handleMandiSelect = (value, props) => {
      this.setState({ isSpinnerOnTable: true });

      this.setState((prevState) => ({
        ...prevState,
        selectedMandi: props.key,
        dropdownData: {
          ...prevState.dropdownData,
        },
        lastClicked: value === "All" ? "MandiAll" : "Mandi",
        selectedMandiVal: value,
      }));
      this.GetTableDataSummary(
        this.state.startDate !== ""
          ? this.state.startDate
          : moment().format("YYYY-MM-DD"),
        this.state.endDate !== ""
          ? this.state.endDate
          : moment().format("YYYY-MM-DD"),
        this.state.buyerType,
        this.state.selectedBranch,
        this.state.selectedRegion,
        this.state.selectedHub,
        value === "All" ? "" : props.key,
        this.state.selectedVariety
      );
    };
    //#endregion

    return (
      <div className="summary" style={{ height: window.innerHeight - 85 }}>
        <Spin spinning={this.state.isSpinnerOn} tip="Loading...">
          <Spin
            spinning={this.state.isSpinnerOnProgress}
            tip="Please wait while the file is being Downloaded..."
          >
            <div
              className="download-button-container"
              style={{
                display: `${this.state.favouriteChecked ? "none" : "unset"}`,
              }}
            >
              {this.state.ProgressClicked && (
                <Progress
                  type="circle"
                  percent={this.state.downloaddata}
                  width={37}
                  strokeColor={{ "0%": "#108ee9", "100%": "#87d068" }}
                  style={{
                    paddingRight: "5px",
                  }}
                  trailColor="#003285"
                />
              )}

              {
                <Button
                  disabled={this.state.differenceofDays > 180}
                  onClick={() => {
                    this.setState({ isSpinnerOnProgress: true });
                    this.setState({ downloaddata: 0 });
                    this.setState({ ProgressClicked: true });
                    const downloadParams = {
                      download: true,
                      start_date: this.state.startDate,
                      end_date: this.state.endDate,
                      arrival_type: this.state.buyerType,
                      branch_id: this.state.selectedBranch,
                      region_id: this.state.selectedRegion,
                      hub_id: this.state.selectedHub,
                      mandi_id: this.state.selectedMandi,
                      variety_id: this.state.selectedVariety,
                    };
                    const fileHeaders = {
                      Authorization:
                        "Bearer " + localStorage.getItem("accessToken"),
                    };

                    // API.get(`/audits`, {
                    //   headers:fileHeaders,
                    //   params: downloadParams,
                    // })
                    //   .then((fileresponse) => {

                    //     if (fileresponse.data.status) {
                    //#region file download

                    Axios({
                      url: `${baseURL}/audits`,
                      method: "GET",
                      headers: fileHeaders,
                      params: downloadParams,
                      responseType: "blob",
                      onDownloadProgress: (progressEvent) => {
                        let progress = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        );
                        this.setState({ downloaddata: progress });
                      },
                    })
                      .then((response) => {
                        if (response.status !== 204) {
                          console.log("response, ", response);
                          FileDownLoad(
                            response.data,
                            `Arrival_Summary_${new Date().toLocaleDateString()}.xlsx`
                          );

                          this.setState({ isSpinnerOnProgress: false });
                          setTimeout(
                            function () {
                              this.setState({ ProgressClicked: false });
                            }.bind(this),
                            5000
                          );
                        } else {
                          message.warning("File has no data.");
                          this.setState({
                            isSpinnerOnProgress: false,
                            ProgressClicked: false,
                          });
                        }
                      })
                      .catch((err) => {
                        console.log("response error ", err.response);
                        message.error("File cannot be downloaded");
                        this.setState({
                          isSpinnerOnProgress: false,
                          ProgressClicked: false,
                        });
                      });
                    //#endregion
                    //   } else {
                    //     message.warning("File has no data");
                    //    this.setState({isSpinnerOnProgress:false ,ProgressClicked:false});
                    //   }
                    // })
                    // .catch((err) => {

                    //   console.log("response error ", err.response);
                    //   message.error("Error downloading file");
                    //   this.setState({isSpinnerOnProgress:false ,ProgressClicked:false});
                    // });
                  }}
                  type="primary"
                  shape="round"
                  icon="download"
                  size="large"
                >
                  <div style={{ float: "right" }}>
                    <div style={{ marginTop: "-7px" }}>Download</div>
                    <div
                      style={{
                        fontSize: "11px",
                        lineHeight: "7px",
                        //color: "#b4c8dc",
                      }}
                    >
                      .xlsx report
                    </div>
                  </div>
                </Button>
              }
            </div>

            <Drawer
              height="max-content"
              placement="bottom"
              closable={false}
              mask={false}
              // destroyOnClose
              onClose={this.onDrawerClose}
              visible={this.state.drawerVisible}
            >
              <div className="drawer-container">
                <div className="drawer-text">
                  {this.state.localPostItemList.arrival_entries.length} unsaved
                  changes
                </div>
                <Button className="save-button" onClick={this.setDrawerClose}>
                  Save Changes
                </Button>
              </div>
            </Drawer>
            <div
              className="filter-container"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "13px",
              }}
            >
              {!isTab && (
                <div
                  className="back-button-container"
                  onClick={() => {
                    this.props.handleBackClickInSummary();
                  }}
                >
                  &#10094;{" Back"}
                </div>
              )}
              {/* <div className="filter-container-title">SUMMARY SCREEN</div> */}
              {this.state.buyerType === "Mandi" ||
              this.state.buyerType === "FOR" ||
              this.state.buyerType === "Farmer" ? (
                <Modal
                  key="4"
                  keyboard
                  width="1300px"
                  title={`Audit Trail - ${this.state.buyerType}`}
                  centered
                  onCancel={() => this.setState({ isModalVisible: false })}
                  footer={null}
                  //visible={isModalVisible}
                  visible={this.state.isModalVisible}
                >
                  <div className="table-container audit-table">
                    <Spin
                      spinning={this.state.isSpinnerOnModal}
                      tip="Loading..."
                    >
                      <Table
                        {...modalTableProps}
                        columns={[
                          {
                            title: <TableColumnHeader title="Date & Time" />,
                            dataIndex: "datetime",
                            key: "datetime",
                            width: 120,
                          },
                          {
                            title: (
                              <TableColumnHeader
                                title="Quantity"
                                subtitle="Qntl"
                              />
                            ),
                            dataIndex: "quantity",
                            key: "quantity",
                            width: 70,
                          },
                          {
                            title: (
                              <TableColumnHeader
                                title={
                                  this.state.buyerType === "Mandi"
                                    ? "Modal Price"
                                    : this.state.buyerType === "FOR"
                                    ? "Landing Cost"
                                    : "Naked Grain Price"
                                }
                              />
                            ),
                            dataIndex: "rate",
                            key: "rate",
                            width: 100,
                          },
                          this.buyerType !== "Farmer"
                            ? { width: 0 }
                            : {
                                title: <TableColumnHeader title="Min Price" />,
                                dataIndex: "minPrice",
                                key: "minPrice",
                                width: 70,
                              },
                          this.buyerType !== "Farmer"
                            ? { width: 0 }
                            : {
                                title: <TableColumnHeader title="Max Price" />,
                                dataIndex: "maxPrice",
                                key: "maxPrice",
                                width: 100,
                              },

                          this.state.buyerType === "Mandi"
                            ? {
                                title: "Quality",
                                className: "textCenter",
                                children: [
                                  {
                                    title: "Good",
                                    dataIndex: "good",
                                    key: "good",
                                    width: 70,
                                  },
                                  {
                                    title: "Average",
                                    dataIndex: "average",
                                    key: "average",
                                    width: 70,
                                  },
                                  {
                                    title: "Poor",
                                    dataIndex: "poor",
                                    key: "poor",
                                    width: 70,
                                  },
                                ],
                              }
                            : {
                                title: <TableColumnHeader title="Quality" />,
                                dataIndex: "quality",
                                key: "quality",
                                width: 100,
                              },

                          this.state.buyerType === "Farmer"
                            ? { width: 0 }
                            : {
                                title: "Competitor",
                                dataIndex: "competitors",
                                key: "compName",
                                width: 100,
                                render: (competitors, record) => {
                                  return (
                                    <Popover
                                      overlayClassName="competitor-hover-container"
                                      content={
                                        <CompetitorHoverTable
                                          data={record.competitors}
                                        />
                                      }
                                      title="Competitors"
                                    >
                                      {record.competitors.filter(
                                        (item) => item.key
                                      ).length ? (
                                        <Badge
                                          count={
                                            record.competitors.filter(
                                              (item) => item.key
                                            ).length
                                          }
                                          style={{
                                            backgroundColor: "#fff",
                                            color: "#202020",
                                            boxShadow:
                                              "0 0 0 1px #d9d9d9 inset",
                                            cursor: "pointer",
                                          }}
                                        />
                                      ) : null}
                                    </Popover>
                                  );
                                },
                              },
                          {
                            title: "Remarks",
                            dataIndex: "remarks",
                            key: "remarks",
                            width: 120,
                          },
                          {
                            title: "Entry Status",
                            key: "isActive",
                            dataIndex: "isActive",
                            width: 70,
                          },
                          {
                            title: "Modified By",
                            className: "textCenter",
                            children: [
                              {
                                title: "Emp ID",
                                key: "modified_by",
                                dataIndex: "modified_by",
                                width: 90,
                              },
                              //   ]
                              // },
                              // {
                              //   title: "Modified By",
                              // children: [
                              {
                                title: "Emp Name",
                                key: "modified_by_name",
                                dataIndex: "modified_by_name",
                                width: 100,
                              },
                            ],
                          },
                        ]}
                        dataSource={this.state.mandiModalTableData}
                      />
                    </Spin>
                  </div>
                </Modal>
              ) : null}

              <div
                className="summary-filters"
                style={{
                  width: `${
                    this.state.buyerType === "Mandi" ? "1140px" : "965px"
                  }`,
                }}
              >
                <div className="filter-select">
                  <div
                    className="filter-select-title"
                    style={{
                      paddingLeft: "23px",
                    }}
                  >
                    Date Range
                  </div>
                  <RangePicker
                    defaultValue={[moment(), moment()]}
                    minDate={moment().toDate()}
                    ranges={{
                      Today: [moment(), moment()],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Past 7 days": [moment().subtract(7, "days"), moment()],
                      "This Month": [moment().startOf("month"), moment()],
                      "Last Month": [
                        moment()
                          .startOf("month")
                          .subtract(1, "days")
                          .startOf("month"),
                        moment().startOf("month").subtract(1, "days"),
                      ],
                    }}
                    onChange={this.onChange}
                  />
                </div>
                <div className="filter-select">
                  <div className="filter-select-title">Type</div>
                  <CustomSelectSummary
                    //onSelect={setBuyerType}
                    connectState
                    placeholder={
                      this.state.selectedBuyerType !== ""
                        ? this.state.selectedBuyerType
                        : this.state.buyerType
                    }
                    handleSelect={handleTypeSelect}
                    list={["Mandi", "FOR", "Farmer"]}
                    disabled={this.state.favouriteChecked}
                  />
                </div>

                <div className="filter-select">
                  <div className="filter-select-title">Variety</div>
                  <CustomSelectSummary
                    placeholder={
                      this.state.selectedVarietyVal === ""
                        ? "Select Variety"
                        : this.state.selectedVarietyVal
                    }
                    allEnabled
                    disabled={false}
                    list={varietyName}
                    keyList={varietyId}
                    handleSelect={handleVarietySelect}
                  />
                </div>

                <div className="filter-select">
                  <div className="filter-select-title">Branch</div>
                  <CustomSelectSummary
                    placeholder={
                      this.state.lastClicked === ""
                        ? "Select Branch"
                        : this.state.lastClicked === "BranchAll"
                        ? "All"
                        : this.state.selectedBranchVal
                    }
                    allEnabled
                    disabled={this.state.dropdownData.branches == null}
                    list={branchName}
                    keyList={branchId}
                    handleSelect={handleBranchSelect}
                  />
                </div>

                <div className="filter-select">
                  <div className="filter-select-title">Hub</div>
                  <CustomSelectSummary
                    placeholder={
                      this.state.lastClicked === ""
                        ? "Select Hub"
                        : this.state.lastClicked === "HubAll"
                        ? "All"
                        : this.state.selectedHubVal
                    }
                    allEnabled
                    list={hubName}
                    keyList={hubId}
                    handleSelect={handleHubSelect}
                    disabled={
                      this.state.dropdownData.hubs == null ||
                      this.state.lastClicked === "BranchAll" ||
                      this.state.favouriteChecked
                    }
                  />
                </div>

                {this.state.buyerType === "Mandi" && (
                  <div className="filter-select">
                    <div className="filter-select-title">Mandi</div>
                    <CustomSelectSummary
                      placeholder={
                        this.state.lastClicked === "Hub" ||
                        this.state.lastClicked === ""
                          ? this.state.defaultMandi
                          : this.state.selectedMandiVal
                      }
                      allEnabled
                      list={mandiName}
                      handleSelect={handleMandiSelect}
                      keyList={mandiId}
                      disabled={
                        this.state.dropdownData.mandis == null ||
                        this.state.favouriteChecked
                      }
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="show-fav-container">
              <Checkbox
                checked={this.state.favouriteChecked}
                disabled={
                  this.state.selectedBranchVal === "All" ||
                  this.state.selectedBranchVal === ""
                }
                style={{
                  opacity: `${this.state.buyerType !== "Mandi" ? "0" : "1"}`,
                  pointerEvents: `${
                    this.state.buyerType !== "Mandi" ? "none" : "all"
                  }`,
                }}
                onChange={(e) =>
                  this.setState({ favouriteChecked: e.target.checked })
                }
              >
                Show favourite mandis
              </Checkbox>
            </div>
            {this.state.isTableVisible ? (
              <div className="table-container">
                <Spin spinning={this.state.isSpinnerOnTable} tip="Loading...">
                  <Table
                    // onRow={(record, rowIndex) => {
                    //   return {
                    //     onMouseEnter: event => {
                    //       this.setState(prevState => ({
                    //         ...prevState,
                    //         selectedRowKey: record.key,
                    //         mandiModalTableData: []
                    //       }));
                    //     } // click row
                    //   };
                    // }}
                    {...tableProps}
                    columns={[
                      {
                        title: "Date",
                        dataIndex: "date",
                        key: "date",
                        width: 100,
                      },
                      {
                        title: "Branch",
                        dataIndex: "branch",
                        key: "branch",
                        width: 140,
                      },
                      // {
                      //   title: "Region",
                      //   dataIndex: "region",
                      //   key: "region",
                      //   width: 140
                      // },
                      {
                        title: "Hub",
                        dataIndex: "hub",
                        key: "hub",
                        width: 140,
                      },
                      this.state.buyerType === "Mandi"
                        ? {
                            title: "Mandi",
                            dataIndex: "mandi",
                            key: "mandi",
                            width: 140,
                            render: (mandi, record) => {
                              // console.log(record);
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {record.isMainMandi ? (
                                    <div className="round-icon"></div>
                                  ) : (
                                    <div className="round-icon-disabled"></div>
                                  )}

                                  <div>{mandi}</div>
                                </div>
                              );
                            },
                          }
                        : { width: 0 },
                      {
                        title: "Variety",
                        dataIndex: "variety",
                        key: "variety",
                        width: 140,
                      },
                      {
                        title: <TableColumnHeader title="Qty" subtitle="Qntl" />,
                        dataIndex: "quantity",
                        key: "quantity",
                        width: 70,
                      },
                      {
                        title: (
                          <TableColumnHeader
                            title={
                              this.state.buyerType === "Mandi"
                                ? "Modal Price"
                                : this.state.buyerType === "FOR"
                                ? "Landing Cost"
                                : "Naked Grain Price"
                            }
                            subtitle={"₹/Qntl"}
                          />
                        ),
                        dataIndex: "rate",
                        key: "rate",
                        width: 110,
                        render: (rate, rowRecord) => {
                          // console.log("Date",rowRecord.date,isBeforeTwoDays(rowRecord.date))
                          return (
                            <div className="quantity-container">
                              <div
                                className={
                                  this.state.localPostItemList.arrival_entries.find(
                                    (element) => element.key === rowRecord.key
                                  )
                                    ? "visited"
                                    : ""
                                }
                              >
                                <InputNumber
                                  min={getVarietyMinPrice(
                                    this.props.allVarietyInfo,
                                    rowRecord.variety,
                                    false
                                  )}
                                  max={getVarietyMaxPrice(
                                    this.props.allVarietyInfo,
                                    rowRecord.variety,
                                    false
                                  )}
                                  disabled={
                                    !canUserEdit(
                                      user,
                                      rowRecord.branchId,
                                      FeatureCodes.ARRIVAL_SUMMARY_EDIT
                                    ) ||
                                    isBeforeTwoDays(rowRecord.date) ||
                                    rowRecord.favMandi
                                  }
                                  formatter={(value) =>
                                    `${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  onBlur={(event) => {
                                    this.setSendSummaryData(
                                      event.target.value.replace(",", ""),
                                      rowRecord
                                    );
                                  }}
                                  placeholder={`${rate}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                  defaultValue={rate}
                                  // value={rowRecord.price}
                                />
                              </div>
                            </div>
                          );
                        },
                      },
                      this.buyerType !== "Farmer"
                        ? { width: 0 }
                        : {
                            title: "Price",
                            children: [
                              {
                                title: "Min",
                                dataIndex: "minPrice",
                                key: "minPrice",
                                width: 70,
                              },
                              {
                                title: "Max",
                                dataIndex: "maxPrice",
                                key: "maxPrice",
                                width: 70,
                              },
                            ],
                          },
                      this.state.buyerType === "Mandi"
                        ? {
                            title: "Quality",
                            children: [
                              {
                                title: "Good",
                                dataIndex: "good",
                                key: "good",
                                width: 70,
                              },
                              {
                                title: "Average",
                                dataIndex: "average",
                                key: "average",
                                width: 70,
                              },
                              {
                                title: "Poor",
                                dataIndex: "poor",
                                key: "poor",
                                width: 70,
                              },
                            ],
                          }
                        : {
                            title: <TableColumnHeader title="Quality" />,
                            dataIndex: "quality",
                            key: "quality",
                            width: 100,
                          },

                      this.state.buyerType === "Farmer"
                        ? { width: 0 }
                        : {
                            title: "Competitor",
                            dataIndex: "competitors",
                            key: "compName",
                            width: 100,
                            render: (competitors, record) => {
                              return (
                                <Popover
                                  overlayClassName="competitor-hover-container"
                                  content={
                                    <CompetitorHoverTable
                                      data={record.competitors}
                                    />
                                  }
                                  title="Competitors"
                                >
                                  {record.competitors.filter((item) => item.key)
                                    .length ? (
                                    <Badge
                                      count={record.competitors.length}
                                      style={{
                                        backgroundColor: "#fff",
                                        color: "#202020",
                                        boxShadow: "0 0 0 1px #d9d9d9 inset",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : null}
                                </Popover>
                              );
                            },
                          },
                      this.state.buyerType === "Mandi"
                        ? {
                            title: "Mandi Status",
                            dataIndex: "status",
                            key: "status",
                            width: 80,
                          }
                        : { width: 0 },
                      {
                        title: "Remarks",
                        dataIndex: "remarks",
                        key: "remarks",
                        width: 120,
                      },
                      {
                        title: "More Details",
                        dataIndex: "moreDetails",
                        key: "moreDetails",
                        width: 100,
                        render: (moreDetails, rowRecord) => {
                          return (
                            <div
                              onClick={() => {
                                this.GetModalTableDataSummary(rowRecord.key);

                                //setAuditModalVisibility(true);
                                this.setState({ isModalVisible: true });
                              }}
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                            >
                              Audit Trail
                            </div>
                          );
                        },
                      },
                    ]}
                    dataSource={setDataForTable()}
                    ref={(el) => {
                      this.messagesEnd = el;
                    }}
                    // onChange={() => (document.documentElement.scrollTop = 0)}
                  />
                </Spin>
              </div>
            ) : (
              <Alert
                style={{ margin: "10px" }}
                message="Informational Notes"
                description={
                  this.state.differenceofDays > 180
                    ? "The data for the selected time period is too big.Excel file cannot be downloaded since the time period is more than 6 months."
                    : "The data for the selected time period is too big. You can use the button at the bottom right to download the file"
                }
                type="info"
                showIcon
              />
            )}
          </Spin>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  allVarietyInfo: selectAllVarietyInfo,
  isModalVisible: selectIsModalVisible,
  userData: selectUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAuditModalVisibility: (visibilityState) =>
      dispatch(setAuditModalVisibility(visibilityState)),
    setSummaryVisibility: (isEnabled) =>
      dispatch(setSummaryVisibility(isEnabled)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
