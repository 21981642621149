import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, withRouter } from "react-router-dom";

import "./App.css";
import "antd/dist/antd.css";
// import "leaflet/dist/leaflet.css";
import moment from "moment";

import packageJson from '../package.json';

import { Layout, message, Button } from "antd";

import MainLayout from "./components/layout/layout.component";
import Login from "./pages/login/login.page";
import PrivateRoute from "./components/private-route/private-route";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectLoginStatus } from "./redux/user/user.selectors";
import Summary from "./pages/summary/summary.page";

import { SignOutAPI } from "./auth/utils";
import API from "./api"
import { setUserToken } from "./services/axios-interceptor"

import CacheBuster from './components/CacheBuster/CacheBuster.js';

import {
  setAccessToken,
  setUserRBACDetails,
  setAllUsers,
  setAllBranches,
  setReportData,
  setRefreshToken,
  setUserLoggedStatus
} from "./redux/user/user.actions";

///////////////////////////////////////////////////////////
//#region arrival entry optimization actions and selectors.
import {
  saveAllVarietyInfo,
  setArrivalData,
  setAllMandis,
  setBranches,
  setRegions,
  setHubs,
  setMandis,
  setVarieties,
  setRemainingVarietyList,
  setHubVarieties,
  setRemainingHubVarietyList,
  setBranchID,
  setRegionID,
  setHubID,
  setMandiID,
} from "./redux/arrival-entry/arrival-entry.actions";
import {
  selectArrivalData,
  selectSummaryScreenVisibility,
  selectBranches,
  selectRegions,
  selectHubs,
  selectMandis,
  selectVarieties,
  selectAllVarietyInfo,
  selectHubVarieties,
  selectBranchID,
  selectRegionID,
  selectHubID,
  selectMandiID,
} from "./redux/arrival-entry/arrival-entry.selectors";
import { setCurrentArrivalScreen } from "./redux/map/map.actions";
import { selectCurrentArrivalScreen } from "./redux/map/map.selectors";
//#endregion
///////////////////////////////////////////////////////////

import {
  setRollUp,
  setRemarks,
  setHubTableData,
  setActualHubTableData,
  setHubDataPostFOR,
  setActualHubDataPostFOR,
  setDecisionBranches,
  setDecisionRegions,
  setDecisionVarieties,
  setSelectedBranchID,
  setSelectedRegionID,
  setSelectedVarietyID,
} from "./redux/buying-decision/buying-decision.actions";

import {
  selectHubTableData,
  selectDecisionBranches,
  selectDecisionRegions,
  selectDecisionVarieties,
  selectSelectedBranchID,
  selectSelectedRegionID,
  selectSelectedVarietyID,
} from "./redux/buying-decision/buying-decision.selectors";
import { setCoordinates } from "./redux/weather/weather.actions";
// import { any } from "prop-types";

const { Footer } = Layout;
global.appVersion = packageJson.version;

/**
 * @description Entry point of the application
 * @component
 *
 */
const App = (props) => {
  const { isLogged, setUserRBACDetails, history, setOrdinates } = props;
  const[clickDisable,SetClickDisable]= useState(false);
  // const history = useHistory();

  //Logout if user is not logged in
  useEffect(() => {
    if (!isLogged) {
      history.push("/login");
    }
  }, [isLogged, history]);


  useEffect(() => {
    checkIsLogin();
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setOrdinates({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          if (error.code === 1) {
            console.log("Permission denied")
            // this.setState({ permissionDenied: true });
          }
        }
      );
    } else {
      console.log('Geolocation is not supported by your browser.');
    }  }, [])

  const checkIsLogin = async () => {
    const accessToken = await localStorage.getItem("accessToken");

    if (accessToken) {

      const refreshToken = await localStorage.getItem("refreshToken");
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      setUserToken(accessToken);
      props.setUserLoggedStatus(true);
      if (
        localStorage.getItem("roleID") &&
        localStorage.getItem("userTerritories")
        //  &&
        // localStorage.getItem("location_access") &&
        // localStorage.getItem("non_location_access")
      ) {
        setUserRBACDetails(
          parseInt(localStorage.getItem("roleID")),
          localStorage.getItem("userName"),
          localStorage
            .getItem("userTerritories")
            .split(",")
            .map((item) => parseInt(item)),
          localStorage
            .getItem("location_access")
            .split(",")
            .map((item) => item.toString()),
          localStorage
            .getItem("non_location_access")
            .split(",")
            .map((item) => item.toString()),
          localStorage
            .getItem("user_features")
            .split(",")
            .map((item) => item.toString()),
        );
      }
    }
  }
  //Mention in login.tsx
  useEffect(() => {

    if (!isLogged) return;
    //#region  get the roleId and userTerritories from localstorage to redux for use.

    //#endregion

    //#region if refreshToken is present, get a new accesstoken anyway,
    //and get all varieties & comments && buying decision data restored back to redux

    // if (localStorage.getItem("refreshToken") !== null) {
    //   // #region  get new access token

    // const refreshHeader = {
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + localStorage.getItem("refreshToken"),
    // };

    // API.post("/refresh", null, {
    //   headers: refreshHeader,
    // })
    //   .then((response) => {
    //     if (response.status) {
    // localStorage.setItem(
    //   "accessToken",
    //   response.data.data.access_token
    // );
    // props.setAccessToken(response.data.data.access_token);

    // localStorage.setItem(
    //   "accessTokenExpiry",
    //   response.data.data.access_token_expiry
    // );

    //#region REDUX STORE RESTORATION

    /////////////////////////////////////////////////
    //#region Arrival entry hierarchy API
    // const arrivalHeader = {
    //   Authorization: "Bearer " + response.data.data.access_token,
    // };
    API.get("/territories")
      .then((arrivalResponse) => {
        if (arrivalResponse.data.status) {
          props.setArrivalData(arrivalResponse.data.data);

          //#region save all mandis into redux
          let allMandiList = [];
          arrivalResponse.data.data.branch_list.map((branch) => {
            branch.hub_list.map((hub) => {
              if (hub.mandi_list.length !== 0) {
                hub.mandi_list.map((mandi) => {
                  let modifiedMandi = {
                    mandi_name: mandi.mandi_name,
                    mandi_id: mandi.mandi_id,
                  };
                  allMandiList.push(modifiedMandi);
                });
              }
            });
          });

          props.setAllMandis(allMandiList);
          //#endregion

          //set all branches information & select first branch as default
          props.setBranches(arrivalResponse.data.data.branch_list);
          props.setBranchID(
            arrivalResponse.data.data.branch_list[0].branch_id
          );

          //set all hubs under the first branch and set first hub as default.
          // for mandis : hubs are the ones with mandi_list not empty.
          // for FOR and Farmer, hubs include all items in hub_list.

          let mandiHubList = arrivalResponse.data.data.branch_list[0].hub_list.filter(
            (item) => item.mandi_list.length !== 0
          );

          if (props.currentScreen === "Mandi") {
            props.setHubs(mandiHubList);
            props.setHubID(mandiHubList[0].hub_id);
          } else {
            props.setHubs(
              arrivalResponse.data.data.branch_list[0].hub_list
            );
            props.setHubID(
              arrivalResponse.data.data.branch_list[0].hub_list[0]
                .hub_id
            );
          }

          // #region get and store previous card data for FOR and farmer and store into local states
          // get previous card data for FOR
          if (props.currentScreen === "FOR") {
            getPreviousFarmerAndFORCardData(
              arrivalResponse.data.data.branch_list[0].branch_id,
              null,
              arrivalResponse.data.data.branch_list[0].hub_list[0]
                .hub_id,
              true
            );
          }

          // get previous card data for farmer
          if (props.currentScreen === "Farmer") {
            getPreviousFarmerAndFORCardData(
              arrivalResponse.data.data.branch_list[0].branch_id,
              null,
              arrivalResponse.data.data.branch_list[0].hub_list[0]
                .hub_id,
              false
            );
          }
          // #endregion

          //set all mandis under the first hub and set the first mandi as default
          props.setMandis(mandiHubList[0].mandi_list);
          props.setMandiID(mandiHubList[0].mandi_list[0].mandi_id);

          props.setVarieties(
            mandiHubList[0].mandi_list[0].varieties
          );

          // region id is only for mandis. it's inside the current selected mandi item
          props.setRegionID(
            mandiHubList[0].mandi_list[0].region_id
          );

          //#region  resetting remainingvarietyNames in redux
          let currentVarietyNames = mandiHubList[0].mandi_list[0].varieties.map(
            (item) => item.variety_name
          );
          let allVarietyNames = props.allVarietyInfo.map(
            (item) => item.variety_name
          );
          let remainingVarietyNames = allVarietyNames.filter(
            (item) => !currentVarietyNames.includes(item)
          );

          props.setRemainingVarietyList(remainingVarietyNames);
          //#endregion

          // get previous card data in first mandi and store to state.
          getPreviousMandiCardData(
            arrivalResponse.data.data.branch_list[0].branch_id,
            mandiHubList[0].mandi_list[0].region_id,
            mandiHubList[0].mandi_list[0].mandi_id
          );
          SetClickDisable(true);
          //this can be removed after fixing the previous data getting function above.
          // this.setState({ isSpinnerOn: false });
        } else {
        }
      })
      .catch((err) => {
      });

    //#region arrival entry assistive functions

    // called after getting mandi data
    const getPreviousMandiCardData = (
      branch_id,
      region_id,
      mandi_id,
      screen
    ) => {
      //#region get all previous data in the cards

      // const currentHeaders = {
      //   Authorization: "Bearer " + response.data.data.access_token,
      // };

      const prevDataParams = {
        branch_id: branch_id,
        mandi_id: mandi_id,
        arrival_type: "Mandi",
      };

      // this.setState({ isSpinnerOn: true });
      API.get("/varieties/arrivals", {
        // headers: currentHeaders,
        params: prevDataParams,
      })
        .then((previousDataResponse) => {
          if (previousDataResponse.data) {

            // save previous card data in the local state.
            this.setState(
              {
                previousCardDataMandi:
                  previousDataResponse.data.data.varieties,
              },
              () => {
                if (screen) props.setCurrentArrivalScreen(screen);
              }
            );

            forceChanges();

            // this.setState({ isSpinnerOn: false });
          } else {
            message.error(
              "Could not retrieve previous card data.Please try again"
            );
            // this.setState({ isSpinnerOn: false });
          }
        })
        .catch((err) => {
          // this.setState({ isSpinnerOn: false });
        });
      //#endregion
    };

    // called after getting hub data
    const getPreviousFarmerAndFORCardData = (
      branch_id,
      region_id,
      hub_id,
      isFOR,
      screen
    ) => {
      //#region get all previous data in the cards

      // const currentHeaders = {
      //   Authorization: "Bearer " + response.data.data.access_token,
      // };

      const prevDataParams = {
        branch_id: branch_id,
        hub_id: hub_id,
        arrival_type: isFOR ? "FOR" : "Farmer",
      };

      // this.setState({ isSpinnerOn: true });
      API.get(`/varieties/arrivals`, {
        // headers: currentHeaders,
        params: prevDataParams,
      })
        .then((previousDataResponse) => {
          if (previousDataResponse.data) {

            //#region get varieties from the list in a structured format and save it into hubVarieties.
            //        this will handle adding variety cards into the FOR and Farmer screens.

            let currentHubVarietyIDList = previousDataResponse.data.data.varieties.map(
              (item) => item.variety_id
            );


            let currentHubVarieties = currentHubVarietyIDList.map(
              (id) =>
                props.allVarietyInfo.find(
                  (item) => item.variety_id === id
                )
            );


            props.setHubVarieties(currentHubVarieties);

            //#region  resetting remainingHubvarietyNames in redux
            // this can be done easily as assigning all variety names directly to the remainingHubVarietyNames.
            // but just in case the client would want to add a variety list for a hub in future,we keep a null array
            // as the incoming varietylist for the hub. replace the null array with the varietylist names of the hub
            // in case varietylist comes with hub in the future.
            let currentHubVarietyNames = currentHubVarieties.map(
              (item) => item.variety_name
            );
            let allHubVarietyNames = props.allVarietyInfo.map(
              (item) => item.variety_name
            );
            let remainingHubVarietyNames = allHubVarietyNames.filter(
              (item) => !currentHubVarietyNames.includes(item)
            );

            props.setRemainingHubVarietyList(
              remainingHubVarietyNames
            );
            //#endregion

            //#endregion

            // save previous card data in the local state depending upon isFOR.
            isFOR
              ? this.setState(
                {
                  previousCardDataFOR:
                    previousDataResponse.data.data.varieties,
                },
                () => {
                  if (screen)
                    props.setCurrentArrivalScreen(screen);
                }
              )
              : this.setState(
                {
                  previousCardDataFarmer:
                    previousDataResponse.data.data.varieties,
                },
                () => {
                  if (screen)
                    props.setCurrentArrivalScreen(screen);
                }
              );

            forceChanges();

            // this.setState({ isSpinnerOn: false });
          } else {
            //props.setHubVarieties([]);
            message.error(
              "Could not retrieve previous card data.Please try again"
            );
            // this.setState({ isSpinnerOn: false });
          }
        })
        .catch((err) => {
          //  props.setHubVarieties([]);

          message.error(
            "Could not retrieve previous card data.Please try again"
          );
          // this.setState({ isSpinnerOn: false });
        });
      //#endregion
    };

    const forceChanges = () => {
      const { currentScreen, setCurrentArrivalScreen } = props;

      switch (currentScreen) {
        case "Mandi":
          const tempMandiScreen = currentScreen;
          setCurrentArrivalScreen("FOR");
          setCurrentArrivalScreen(tempMandiScreen);

          break;
        case "FOR":
          let tempFORScreen = currentScreen;
          setCurrentArrivalScreen("Farmer");
          setCurrentArrivalScreen(tempFORScreen);
          break;
        case "Farmer":
          const tempFarmerScreen = currentScreen;
          setCurrentArrivalScreen("Mandi");
          setCurrentArrivalScreen(tempFarmerScreen);
          break;
        default:
          break;
      }
      // this.setState({ isSpinnerOn: false });
    };

    //#endregion

    //#endregion
    /////////////////////////////////////////////////

    /////////////////////////////////////////////////
    //#region BUYING_DECISION_INITIALIZATION

    //#region  api sequential calls for buying decision

    // const buyingDecisionHeaders = {
    //   "Content-Type": "application/json",
    //   Authorization: "Bearer " + response.data.data.access_token,
    // };
    const params = {
      table_type: "transaction",
    };

    API.get("/branches", {
      // headers: buyingDecisionHeaders,
      params: params,
    })
      .then((branchResponse) => {
        if (branchResponse.data.status) {
          props.setDecisionBranches(
            branchResponse.data.data.branches
          );
          props.setSelectedBranchID(
            branchResponse.data.data.branches[0].territory_id
          );



          GetRegion(branchResponse.data.data.branches[0].territory_id);
        }
      })
      .catch((err) => console.log("Error from branch : ", err));

    //#endregion

    //#region buying decision assistive functions
    const GetRegion = (branchId) => {
      const params = {
        table_type: "transaction",
        branch_id: branchId,
        mapping: "mapped",
      };
      // API.get(`/branches/${branchId}/regions/`, {
      API.get(`/regions`, {
        // headers: buyingDecisionHeaders,
        params: params,
      })
        .then((regionResponse) => {
          props.setDecisionRegions(
            regionResponse.data.data.regions
          );
          props.setSelectedRegionID(
            regionResponse.data.data.regions[0].region_id
          );


          GetVariety(
            branchId,
            regionResponse.data.data.regions[0].region_id
          );
        })
    };

    const GetVariety = (branchId, regionId) => {
      // const VARIETY_END_POINT = `/branches/${branchId}/regions/${regionId}/`;
      const VARIETY_END_POINT = `/varieties`;
      const params = {
        table_type: "transaction",
        mapping: "mapped",
        region_id: regionId,
      };
      API.get(VARIETY_END_POINT, {
        // headers: buyingDecisionHeaders,
        params: params,
      })
        .then((varietyResponse) => {
          props.setDecisionVarieties(
            varietyResponse.data.data ? varietyResponse.data.data : []
          );

          props.setSelectedVarietyID(
            varietyResponse.data.data
              ? varietyResponse.data.data[0].variety_id
              : ""
          );


          // getHubTableData(
          //   branchId,
          //   regionId,
          //   varietyResponse.data.data[0].variety_id
          // );
        })
        .catch((err) => console.log("Error from variety : ", err));
    };

    const getHubTableData = (branchId, regionId, varietyId) => {
      props.setHubTableData({});

      // props.setFORData([]);
      const params = {
        branch_id: props.selectedBranchID,
        regions: [regionId],
        varities: [varietyId],
      };
      //#region api call for getting buying decision data
      const HUB_DATA_END_POINT = `/buying_decision_planned`;
      API.get(HUB_DATA_END_POINT, { headers: this.headers, params: params })

        // const HUB_DATA_END_POINT = `/branches/${branchId}/regions/${regionId}/variety/${varietyId}`;
        // API.get(HUB_DATA_END_POINT, { headers: buyingDecisionHeaders, })
        .then((buyingResponse) => {

          if (buyingResponse.data) {
            const modifiedHubDataStructure = {
              region: {
                hub_details: buyingResponse.data.data.hub_list.map(
                  (item, idx) => {
                    return { ...item, edited: false };
                  }
                ),
              },
            };
            props.setHubTableData(modifiedHubDataStructure);

            // props.setRollUp(
            //   buyingResponse.data.data.mandi_roll_up,
            //   buyingResponse.data.data.for_roll_up,
            //   buyingResponse.data.data.farmer_roll_up
            // );
            props.setRollUp(
              {},
              buyingResponse.data.data.for_roll_up,
              {}
            );

            //#region set hubFORdata in redux

            const forData = buyingResponse.data.data.hub_list
              .map((item, idx) => {
                if (
                  buyingResponse.data.data.hub_list[idx].source_type !==
                  "mandi"
                ) {
                  return {
                    hub_id: item.hub_id,
                    buying_decision_id: item.buying_decision_id,
                    temp_bd_id: item.temp_bd_id,
                    revised_qty: item.revised_qty,
                    revised_buy_min_price: item.revised_buy_min_price,
                    revised_buy_max_price: item.revised_buy_max_price,
                    remark_id: item.remark_id ? item.remark_id : null,
                    source_type: item.source_type,
                    region_id: item.region_id,
                    variety_id: item.variety_id,
                    claim_type: item.claim_type,
                    division: item.division,
                    hub_name: item.hub_name,
                    variety_name: item.variety_name
                  };
                }
              })
              .filter((item) => item !== undefined);

            props.setHubDataPostFOR(forData);

            //#endregion

            //
            // props.setFORData(props.hubTableData);
            //
          }
        })
        .catch((err) => {
          props.setHubTableData({});

          //
          // props.setFORData([]);
        });

      //#endregion
      //
    };
    //#endregion

    //#endregion
    //////////////////////////////////////////////////

    const getActualHubTableData = (branchId, regionId, varietyId) => {
      props.setActualHubTableData({});

      // props.setFORData([]);
      const params = {
        branch_id: props.selectedBranchID,
        regions: [regionId],
        varities: [varietyId],
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
      };
      //#region api call for getting buying decision data
      const HUB_DATA_END_POINT = `/buying_decision_actual`;
      API.get(HUB_DATA_END_POINT, { headers: this.headers, params: params })

        // const HUB_DATA_END_POINT = `/branches/${branchId}/regions/${regionId}/variety/${varietyId}`;
        // API.get(HUB_DATA_END_POINT, { headers: buyingDecisionHeaders, })
        .then((buyingResponse) => {

          if (buyingResponse.data) {
            const modifiedHubDataStructure = {
              region: {
                hub_details: buyingResponse.data.data.map(
                  (item, idx) => {
                    return { ...item, edited: false };
                  }
                ),
              },
            };
            props.setActualHubTableData(modifiedHubDataStructure);

            // props.setRollUp(
            //   buyingResponse.data.data.mandi_roll_up,
            //   buyingResponse.data.data.for_roll_up,
            //   buyingResponse.data.data.farmer_roll_up
            // );
            props.setRollUp(
              {},
              buyingResponse.data.data.for_roll_up,
              {}
            );

            //#region set hubFORdata in redux

            const forData = buyingResponse.data.data.hub_list
              .map((item, idx) => {
                if (
                  buyingResponse.data.data.hub_list[idx].source_type !==
                  "mandi"
                ) {
                  return {
                    hub_id: item.hub_id,
                    buying_decision_id: item.buying_decision_id,
                    // temp_bd_id: item.temp_bd_id,
                    revised_qty: item.revised_qty,
                    revised_price: item.revised_price,
                    // revised_buy_max_price: item.revised_buy_max_price,
                    remark_id: item.remark_id ? item.remark_id : null,
                    source_type: item.source_type,
                    region_id: item.region_id,
                    variety_id: item.variety_id,
                    claim_type: item.claim_type,
                    division: item.division,
                    hub_name: item.hub_name,
                    ui_purchase_price: null,
                    ui_purchase_qty: null,
                    ui_purchase_remarks: null,
                    variety_name: item.variety_name
                  };
                }
              })
              .filter((item) => item !== undefined);

            props.setActualHubDataPostFOR(forData);

            //#endregion

            //
            // props.setFORData(props.hubTableData);
            //
          }
        })
        .catch((err) => {
          props.setHubTableData({});

          //
          // props.setFORData([]);
        });

      //#endregion
      //
    };
    //#endregion

    //#region api call to get all variety info and store it in redux

    // const varietyHeader = {
    //   Authorization: "Bearer " + response.data.data.access_token,
    // };

    API.get("/varieties")
      .then((varietyResponse) => {
        props.saveAllVarietyInfo(varietyResponse.data.data);
      })
      .catch((err) => {
      });
    //#endregion

    //#region api call to get all remarks and store into redux


    // const remarksHeader = {
    //   Authorization: "Bearer " + response.data.data.access_token,
    // };
    API.get("/remarks")
      .then((remarksResponse) => {
        if (remarksResponse.data.status) {
          const remarksList =
            remarksResponse.data.data.length !== 0
              ? remarksResponse.data.data
              : [];

          props.setRemarks(remarksList);
        } else {
        }
      })
      .catch((err) => {
        // message.error("Error getting remarks");
      });

    //#endregion

    //#region get all the branches and store to users redux store.
    // const branchHeader = {
    //   Authorization: "Bearer " + response.data.data.access_token,
    // };

    API.get("/branches")
      .then((branchResponse) => {
        if (branchResponse.data.status) {
          const allBranches = branchResponse.data.data.branches.map(
            (branch) => {
              return {
                territory_name: branch.territory_name,
                territory_id: branch.territory_id,
              };
            }
          );
          props.setAllBranches(allBranches);
        } else {
        }
      })
      .catch((err) => {
      });

    //#endregion
    /************************************************************************************************************************************ */
    API.get("/reports")
      .then((reportResponse) => {
        if (reportResponse.data.status) {
          const allReports = reportResponse.data.data.map(
            (report) => {
              return {
                param_display_value: report.param_display_value,
                param_data_value: report.param_data_value,
                param_type: report.param_type,
              };
            }
          );
          props.setReportData(allReports);
        } else {

        }
      })
      .catch((err) => {
      });


    /************************************************************************************************************************************ */
    //#region api call to get all user data
    // const userHeader = {
    //   Authorization: "Bearer " + response.data.data.access_token,
    // };

    API.get("/users")
      .then((userResponse) => {
        if (userResponse.data.status) {
          props.setAllUsers(userResponse.data.data);
        } else {
        }
      })
      .catch((err) => {
      });
    //#endregion

    //#endregion
    // } else {

    // }
    // })
    // .catch((err) => {
    //   // clearInterval(intervalName);
    //   SignOutAPI();
    //   props.setAccessToken(null);
    //   localStorage.setItem("isLogged", false);
    //   props.history.push("/login");
    // });
    // } else {
    //   // clearInterval(intervalName);
    //   SignOutAPI();
    //   props.setAccessToken(null);
    //   localStorage.setItem("isLogged", false);
    //   props.history.push("/login");
    // }

    //#endregion

    //#endregion

    // let timeToRepeat =
    //   localStorage.getItem("accessTokenExpiry") === !undefined
    //     ? localStorage.getItem("accessTokenExpiry") * 1000
    //     : 86700 * 1000;


    // const autoCheck = setInterval(() => {
    //   this.checkForAutoLogout(autoCheck);
    // }, timeToRepeat - 60 * 1000); // 1 minute(60000s) before expiry, access token will be refreshed.
  }, [isLogged]);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <div className="App">
            <Switch>
              {/* <Route
                exact
                path="/"
                render={() =>
                  localStorage.getItem("isLogged") === "true" ? (
                    <Redirect to="/home" />
                  ) : (
                    <Login />
                  )
                }
              /> */}
              <Route
                exact
                path="/login"
                render={() =>
                  isLogged ? (
                    <Redirect to="/home" clickDisable={clickDisable} />
                  ) : (
                    <Login />
                  )
                }
              />
              <PrivateRoute
                exact
                path="/home"
                Component={MainLayout}
                isLogged={isLogged}
                clickDisable={clickDisable}
              />
              <Redirect exact path="/" to="/home" />
            </Switch>
            <Footer>
              <h6>&copy; ITC Limited v{packageJson.version}</h6>
            </Footer>

            {process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE}
          </div>
        );
      }}
    </CacheBuster>
  );
}


const mapStateToProps = createStructuredSelector({
  isLogged: selectLoginStatus,

  //////////////////////////////////////////////
  //#region arrival entry optimization selectors
  arrivalData: selectArrivalData,
  currentScreen: selectCurrentArrivalScreen,
  summaryIsEnabled: selectSummaryScreenVisibility,
  branches: selectBranches,
  regions: selectRegions,
  hubs: selectHubs,
  mandis: selectMandis,
  allVarietyInfo: selectAllVarietyInfo,
  branchID: selectBranchID,
  regionID: selectRegionID,
  hubID: selectHubID,
  mandiID: selectMandiID,

  varieties: selectVarieties,
  hubVarieties: selectHubVarieties,

  //#endregion
  ///////////////////////////////////////////////

  hubTableData: selectHubTableData,
  decisionBranches: selectDecisionBranches,
  decisionRegions: selectDecisionRegions,
  decisionVarieties: selectDecisionVarieties,
  selectedBranchID: selectSelectedBranchID,
  selectedRegionID: selectSelectedRegionID,
  selectedVarietyID: selectSelectedVarietyID,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAccessToken: (token) => dispatch(setAccessToken(token)),
    setUserLoggedStatus: (status) => dispatch(setUserLoggedStatus(status)),
    setOrdinates: (data) => dispatch(setCoordinates(data)),

    setUserRBACDetails: (
      id,
      name,
      territories,
      locationFeatures,
      nonLocationFeatures,
      userFeatures
    ) =>
      dispatch(
        setUserRBACDetails(
          id,
          name,
          territories,
          locationFeatures,
          nonLocationFeatures,
          userFeatures
        )
      ),
    setAllUsers: (data) => dispatch(setAllUsers(data)),
    setAllBranches: (branches) => dispatch(setAllBranches(branches)),
    setReportData: (data) => dispatch(setReportData(data)),

    saveAllVarietyInfo: (varieties) => dispatch(saveAllVarietyInfo(varieties)),
    setRemarks: (remarks) => dispatch(setRemarks(remarks)),

    ////////////////////////////////////////////////////////
    //#region Arrival entry optimization actions
    setArrivalData: (data) => dispatch(setArrivalData(data)),

    setAllMandis: (data) => dispatch(setAllMandis(data)),

    setCurrentArrivalScreen: (screenName) =>
      dispatch(setCurrentArrivalScreen(screenName)),
    setBranches: (branches) => dispatch(setBranches(branches)),
    setRegions: (regions) => dispatch(setRegions(regions)),
    setHubs: (hubs) => dispatch(setHubs(hubs)),
    setMandis: (mandis) => dispatch(setMandis(mandis)),

    setVarieties: (varieties) => dispatch(setVarieties(varieties)),
    setRemainingVarietyList: (data) => dispatch(setRemainingVarietyList(data)),

    setHubVarieties: (data) => dispatch(setHubVarieties(data)),
    setRemainingHubVarietyList: (data) =>
      dispatch(setRemainingHubVarietyList(data)),

    setBranchID: (id) => dispatch(setBranchID(id)),
    setRegionID: (id) => dispatch(setRegionID(id)),
    setHubID: (id) => dispatch(setHubID(id)),
    setMandiID: (id) => dispatch(setMandiID(id)),
    //#endregion
    /////////////////////////////////////////////////////

    setRollUp: (mandiData, FORData, farmerData) =>
      dispatch(setRollUp(mandiData, FORData, farmerData)),
    setHubTableData: (data) => dispatch(setHubTableData(data)),
    setActualHubTableData: (data) => dispatch(setActualHubTableData(data)),
    setHubDataPostFOR: (data) => dispatch(setHubDataPostFOR(data)),
    setActualHubDataPostFOR: (data) => dispatch(setActualHubDataPostFOR(data)),
    setDecisionBranches: (data) => dispatch(setDecisionBranches(data)),
    setDecisionRegions: (data) => dispatch(setDecisionRegions(data)),
    setDecisionVarieties: (data) => dispatch(setDecisionVarieties(data)),
    setSelectedBranchID: (id) => dispatch(setSelectedBranchID(id)),
    setSelectedRegionID: (id) => dispatch(setSelectedRegionID(id)),
    setSelectedVarietyID: (id) => dispatch(setSelectedVarietyID(id)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
