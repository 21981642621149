import React, { Component } from "react";
import "./arrival-category-list.styles.css";

import ArrivalCategoryListItemMandi from "../arrival-category-list-item-mandi/arrival-category-list-item-mandi.component";
import ArrivalCategoryListItemFOR from "../arrival-category-list-item-for/arrival-category-list-item-for.component";
import ArrivalCategoryListItemFarmer from "../arrival-category-list-item-farmer/arrival-category-list-item-farmer.component";
import AddNewVarietyCard from "../add-new-variety-card/add-new-variety-card.component";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  setArrivalData,
  setBranches,
  setHubs,
  setMandis,
  addVariety,
  setVarieties,
  setRemainingVarietyList,
  addHubVariety,
  setHubVarieties,
  setRemainingHubVarietyList,
} from "../../redux/arrival-entry/arrival-entry.actions";
import { selectCurrentArrivalScreen } from "../../redux/map/map.selectors";
import {
  selectArrivalData,
  selectBranches,
  selectHubs,
  selectMandis,
  selectAllVarietyInfo,
  selectRemainingVarietyList,
  selectRemainingHubVarietyList,
  selectBranchID,
  selectHubID,
  selectMandiID,
} from "../../redux/arrival-entry/arrival-entry.selectors";
import { selectUserData } from "../../redux/user/user.selectors";

import { canUserEdit } from "../../auth/utils";
import { FeatureCodes } from "../../auth/data";

/**
 * @description Arrival entry card list component which contains cards of various buyer types.
 * @component
 * @extends {Component}
 */
class ArrivalCategoryList extends Component {
  componentDidMount() {
    const allVarietyNames = this.props.allVarietyInfo.map(
      (item) => item.variety_name
    );
    this.props.setRemainingVarietyList(allVarietyNames);
  }

  varietyCount = 0;

  render() {
    const {
      userData,
      branchID,
      currentArrivalScreen,
      varieties,
      hubVarieties,
      remainingVarietyList,
      currentSelectedMandiId,
      currentMandis,
      addVariety,
      setVarieties,
      setRemainingVarietyList,
      previousCardDataMandi,
      previousCardDataFOR,
      previousCardDataFarmer,
      getPreviousDataMandi,
      getPreviousDataFOR,
      getPreviousDataFarmer,
    } = this.props;

    const user = userData;

    /**
     * @description Functions for adding varieties for hubs
     * @param {number} tempVarietyID Variety ID
     * @param {object} realVarietyInfo All variety information
     * @param {string} varietyName Variety name
     * @memberof ArrivalCategoryList
     */
    const onSelectNewHubVariety = (
      tempVarietyID,
      realVarietyInfo,
      varietyName
    ) => {
      // get selected varietyname from redux state 'remainingVarietyList'
      let currentRemainingHubVarietyList = this.props.remainingHubVarietyList;
      currentRemainingHubVarietyList = currentRemainingHubVarietyList.filter(
        (item) => item !== varietyName
      );

      // change varietyName && varietyID to proper values and save back to hub varieties in redux store
      const dirtyVarieties = this.props.hubVarieties;

      const updatedVarieties = dirtyVarieties.map((item) => {
        if (item.variety_id === tempVarietyID) {
          return {
            ...item,
            variety_id: realVarietyInfo.variety_id,
            variety_name: varietyName,
            variety_price_max: realVarietyInfo.variety_price_max,
            variety_price_min: realVarietyInfo.variety_price_min,
          };
        } else return item;
      });

      this.props.setHubVarieties(updatedVarieties);

      // set new remainingVarietyList state in redux
      this.props.setRemainingHubVarietyList(currentRemainingHubVarietyList);

      // TODO: in the future, if the variety list comes,update the redux store below.Refer onSelectNewVariety.
    };

    /**
     * @description Function for adding updated variety into redux.
     * @memberof ArrivalCategoryList
     */
    const addNewHubVariety = () => {
      const currentHubVarietyNames = this.props.hubVarieties.map(
        (item) => item.variety_name
      );

      let allHubVarietyNames = this.props.remainingHubVarietyList;
      allHubVarietyNames = allHubVarietyNames.filter(
        (item) => !currentHubVarietyNames.includes(item)
      );

      this.props.setRemainingHubVarietyList(allHubVarietyNames);

      // const newID = varieties.length + 1;
      const newID = this.varietyCount++;
      const newVarietyData = {
        variety_description: "it is new type",
        variety_id: `NEW_${currentArrivalScreen}_${newID}`,
        variety_name: null,
        variety_price_min: 0,
        variety_price_max: 0,
      };
      this.props.addHubVariety(newVarietyData);
    };

    /**
     * @description Functions for adding varieties for mandis
     * @param {number} tempVarietyID Variety ID
     * @param {object} realVarietyInfo All variety information
     * @param {string} varietyName Variety name
     * @memberof ArrivalCategoryList
     */
    const onSelectNewVariety = (
      tempVarietyID,
      realVarietyInfo,
      varietyName
    ) => {
      // get selected varietyname from redux state 'remainingVarietyList'
      let currentRemainingVarietyList = remainingVarietyList;
      currentRemainingVarietyList = currentRemainingVarietyList.filter(
        (item) => item !== varietyName
      );

      // change varietyName && varietyID to proper values and save back to varieties in redux store
      const dirtyVarieties = varieties;

      // TODO: just a note, not a todo : the commented code snippet below will cause direct refelction on the redux state
      //        without calling the setVarieties fucntion. it's because the code directly mutates the redux state variable
      //        which causes it to change in the store without dispatching the setVarieties action.
      // const updatedVarieties = dirtyVarieties.map(item => {
      //   if (item.variety_id == tempVarietyID) {
      //     item.variety_id = realVarietyInfo.variety_id;
      //     item.variety_name = varietyName;
      //     item.variety_price_max = realVarietyInfo.variety_price_max;
      //     item.variety_price_min = realVarietyInfo.variety_price_min;

      //     return item;
      //   } else return item;
      // });

      const updatedVarieties = dirtyVarieties.map((item) => {
        if (item.variety_id === tempVarietyID) {
          return {
            ...item,
            variety_id: realVarietyInfo.variety_id,
            variety_name: varietyName,
            variety_price_max: realVarietyInfo.variety_price_max,
            variety_price_min: realVarietyInfo.variety_price_min,
          };
        } else return item;
      });

      setVarieties(updatedVarieties);

      // set new remainingVarietyList state in redux
      setRemainingVarietyList(currentRemainingVarietyList);

      //update the redux store

      //#region add varieties to redux mandis
      let selectedMandi = this.props.mandis.find(
        (item) => item.mandi_id === this.props.mandiID
      );

      selectedMandi.varieties = updatedVarieties;

      let selectedMandiIndex = this.props.mandis.findIndex(
        (item) => item.mandi_id === this.props.mandiID
      );

      let modifiedMandis = this.props.mandis;
      modifiedMandis.splice(selectedMandiIndex, 1, selectedMandi);
      setMandis(modifiedMandis);
      //#endregion

      //#region add modified mandis to the hub item.
      let selectedHub = this.props.hubs.find(
        (item) => item.hub_id === this.props.hubID
      );

      //this is the modified hub item.
      selectedHub.mandi_list = modifiedMandis;

      let selectedHubIndex = this.props.hubs.findIndex(
        (item) => item.hub_id === this.props.hubID
      );

      let modifiedHubs = this.props.hubs;
      modifiedHubs.splice(selectedHubIndex, 1, selectedHub);

      this.props.setHubs(modifiedHubs);

      //#endregion

      //#region add modified hubs to the selected branch.

      let selectedBranch = this.props.branches.find(
        (item) => item.branch_id === this.props.branchID
      );
      selectedBranch.hub_list = modifiedHubs;

      let selectedBranchIndex = this.props.branches.findIndex(
        (item) => item.branch_id === this.props.branchID
      );
      let modifiedBranches = this.props.branches;
      modifiedBranches.splice(selectedBranchIndex, 1, selectedBranch);
      this.props.setBranches(modifiedBranches);

      //#endregion

      //#region add modified branches to arrivalData.
    };

    /**
     * @description Function for adding updated variety into redux.
     * @memberof ArrivalCategoryList
     */
    const addNewVariety = () => {
      const currentVarietyNames = varieties.map((item) => item.variety_name);

      let allVarietyNames = remainingVarietyList;
      allVarietyNames = allVarietyNames.filter(
        (item) => !currentVarietyNames.includes(item)
      );

      setRemainingVarietyList(allVarietyNames);

      const newID = varieties.length + 1;
      const newVarietyData = {
        variety_description: "it is new type",
        variety_id: `NEW_${currentArrivalScreen}_${newID}`,
        variety_name: null,
        variety_price_min: 0,
        variety_price_max: 0,
      };
      addVariety(newVarietyData);
    };

    //

    /**
     * @description Check if current selected mandi is closed or not.
     * @returns boolean
     * @memberof ArrivalCategoryList
     */
    const isCurrentMandiClosed = () => {
      const currentSelectedMandi =
        currentMandis &&
        currentMandis.filter(
          (item) => item.mandi_id === currentSelectedMandiId
        );

      let currentSelectedMandiStatus =
        currentSelectedMandi.length !== 0 &&
        currentSelectedMandi[0].mandi_status;

      currentSelectedMandiStatus = currentSelectedMandiStatus
        ? currentSelectedMandiStatus === "closed" || currentSelectedMandiStatus === "no arrivals"
          ? true
          : false
        : false;

      return currentSelectedMandiStatus;
    };

    const isCurrentHubNoArrivalsSelected = () => {
      const currentSelectedHub =
        this.props.hubs &&
        this.props.hubs.filter(
          (item) => item.hub_id === this.props.hubID
        );

      let currentSelectedHubStatus =
      currentSelectedHub.length !== 0 &&
      currentSelectedHub[0].hub_status;

      currentSelectedHubStatus = currentSelectedHubStatus
        ?  currentSelectedHubStatus === "no arrivals"
          ? true
          : false
        : false;

      return currentSelectedHubStatus;
    };

    switch (currentArrivalScreen) {
      case "Mandi":
        return (
          <div className="arrival-category-list-container">
            {varieties && currentMandis.length !== 0
              ? varieties.map((variety) => (
              
                <div
                  key={variety.variety_id}
                  className={`${isCurrentMandiClosed() ||
                    !canUserEdit(user, branchID, FeatureCodes.ARRIVALS_ENTRY)
                    ? "locked"
                    : ""
                    } `}
                >
                
                 <ArrivalCategoryListItemMandi
                    cardData={
                      previousCardDataMandi
                        ? previousCardDataMandi.filter(
                          (cardItem) =>
                            cardItem.variety_id === variety.variety_id
                        )
                        : []
                    }
                    key={variety.variety_id}
                    varietyName={variety.variety_name}
                    varietyMinRange={variety.variety_price_min}
                    varietyMaxRange={variety.variety_price_max}
                    varietyID={variety.variety_id}
                    remainingVarietyList={remainingVarietyList}
                    onSelectNewVariety={onSelectNewVariety}
                    getPreviousData={getPreviousDataMandi}
                  />
                </div>
              ))
              : null}
              
            {varieties &&
              varieties.length < this.props.allVarietyInfo.length &&
              canUserEdit(user, branchID, FeatureCodes.ARRIVALS_ENTRY) &&
              !isCurrentMandiClosed() ? (
              <AddNewVarietyCard
                currentArrivalScreen={currentArrivalScreen}
                onClick={() => addNewVariety()}
                mandis={this.props.mandis}
                mandiID={this.props.mandiID}
              />
            ) : null}
          </div>
        );
      case "FOR":
        return (
          <div className="arrival-category-list-container" >
          {hubVarieties // replace hubVarieties  with for all cards "varieties"
              ? hubVarieties.map((variety) => (
                <div
                  className={`${isCurrentHubNoArrivalsSelected() || !canUserEdit(user, branchID, FeatureCodes.ARRIVALS_ENTRY)
                    ? "locked"
                    : ""
                    }`}
                  key={variety.variety_id}
                > 
               
                  <ArrivalCategoryListItemFOR
                    cardData={
                      previousCardDataFOR
                        ? previousCardDataFOR.filter(
                          (cardItem) =>
                            cardItem.variety_id === variety.variety_id
                        )
                        : []
                    }
                    key={variety.variety_id}
                    varietyName={variety.variety_name}
                    varietyMinRange={variety.variety_price_min}
                    varietyMaxRange={variety.variety_price_max}
                    varietyID={variety.variety_id}
                    remainingVarietyList={this.props.remainingHubVarietyList}
                    onSelectNewVariety={onSelectNewHubVariety}
                    getPreviousData={getPreviousDataFOR}
                  />
                </div>
              ))
              : null}
    
            {hubVarieties &&
              hubVarieties.length < this.props.allVarietyInfo.length &&
              canUserEdit(user, branchID, FeatureCodes.ARRIVALS_ENTRY) ? (
              <AddNewVarietyCard
                currentArrivalScreen={currentArrivalScreen}
                onClick={() => addNewHubVariety()}
                hubs={this.props.hubs}
                hubID={this.props.hubID}
              />
            ) : null}
          </div>
        );
      case "Farmer":
        return (
          <div className="arrival-category-list-container" >
            {hubVarieties
              ? hubVarieties.map((variety) => (
                <div
                  key={variety.variety_id}
                  className={`${!canUserEdit(user, branchID, FeatureCodes.ARRIVALS_ENTRY)
                    ? "locked"
                    : ""
                    }`}
                >
                  <ArrivalCategoryListItemFarmer
                    cardData={
                      previousCardDataFarmer
                        ? previousCardDataFarmer.filter(
                          (cardItem) =>
                            cardItem.variety_id === variety.variety_id
                        )
                        : []
                    }
                    key={variety.variety_id}
                    varietyName={variety.variety_name}
                    varietyMinRange={variety.variety_price_min}
                    varietyMaxRange={variety.variety_price_max}
                    varietyID={variety.variety_id}
                    remainingVarietyList={this.props.remainingHubVarietyList}
                    onSelectNewVariety={onSelectNewHubVariety}
                    getPreviousData={getPreviousDataFarmer}
                  />
                </div>
              ))
              : null}
            {hubVarieties &&
              hubVarieties.length < this.props.allVarietyInfo.length &&
              canUserEdit(user, branchID, FeatureCodes.ARRIVALS_ENTRY) ? (
              <AddNewVarietyCard
                currentArrivalScreen={currentArrivalScreen}
                onClick={() => addNewHubVariety()}
              />
            ) : null}
          </div>
        );

      default:
        return null;
    }
  }
}

const mapStateToProps = createStructuredSelector({
  currentArrivalScreen: selectCurrentArrivalScreen,
  allVarietyInfo: selectAllVarietyInfo,
  userData: selectUserData,

  remainingVarietyList: selectRemainingVarietyList,

  // hubVarieties: selectHubVarieties,
  remainingHubVarietyList: selectRemainingHubVarietyList,

  branchID: selectBranchID,
  hubID: selectHubID,
  mandiID: selectMandiID,

  arrivalData: selectArrivalData,
  branches: selectBranches,
  hubs: selectHubs,
  mandis: selectMandis,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setArrivalData: (data) => dispatch(setArrivalData(data)),
    setBranches: (data) => dispatch(setBranches(data)),
    setHubs: (data) => dispatch(setHubs(data)),
    setMandis: (data) => dispatch(setMandis(data)),

    addVariety: (newVariety) => dispatch(addVariety(newVariety)),
    setVarieties: (data) => dispatch(setVarieties(data)),
    setRemainingVarietyList: (data) => dispatch(setRemainingVarietyList(data)),

    addHubVariety: (variety) => dispatch(addHubVariety(variety)),
    setHubVarieties: (data) => dispatch(setHubVarieties(data)),
    setRemainingHubVarietyList: (data) =>
      dispatch(setRemainingHubVarietyList(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArrivalCategoryList);
