import React, { Component } from "react";
import "./quality-value-slider.styles.css";

import qualityIcon from "../../assets/Gr_Quality.svg";

import CustomIcon from "../custom-icon/custom-icon.component";
import CustomDropdown from "../custom-dropdown/custom-dropdown.component";
import NumericInput from "../numeric-input/numeric-input.component";

import { Card } from "antd";

/**
 *
 * @component
 * @description Quality group input container used in arrivals entry card component
 * @property {boolean} isDropdown true for a dropdown quality selector, false for a 3 set input field.
 * @property {string} quality the value in the dropdown (Good/Average/Poor)
 * @property {number} qualityPoor the percentage of arrivals having poor quality.
 * @property {number} qualityAverage the percentage of arrivals having average quality.
 * @property {number} qualityGood the percentage of arrivals having good quality.
 */
class QualityValueSlider extends Component {
  state = {
    quality: {
      good: 0,
      average: 100,
      poor: 0,
    },
  };

  list = ["Good", "Average", "Poor"];

  render() {
    const {
      setParentState,
      typeVariety,
      nameVariety,
      isDropdown,
      quality,
      qualityPoor,
      qualityAverage,
      qualityGood,
    } = this.props;

    return (
      <div className="quality-container">
        <Card>
          <div className="value-slider-container">
            <div className="value-slider-component">
              <div className="icon-title-container">
                <CustomIcon className="side-icon" image={qualityIcon} />
                <h5 className="icon-text">Quality</h5>
                {isDropdown ? (
                  <div className="conditional-custom-dropdown">
                    <CustomDropdown
                      defaultValue={quality ? quality : "Average"}
                      setParentState={setParentState}
                      typeVariety={typeVariety}
                      nameVariety={nameVariety}
                      list={this.list}
                    />
                  </div>
                ) : null}
              </div>
              {isDropdown ? null : (
                <div className="input-field-container">
                  <div className="text-line-container">
                    <div className="quality-input-container">
                      <span>Good</span>
                      <NumericInput
                        key={1}
                        defaultValue={qualityGood}
                        minRange={0}
                        maxRange={100} //{setMaxRange("good")}
                        unitText="%"
                        setParentState={setParentState}
                        typeVariety="main"
                        nameVariety="QualityHigh"
                        qualityName="good"
                      />
                    </div>

                    <div className="quality-input-container">
                      <span>Average</span>
                      <NumericInput
                        key={2}
                        defaultValue={qualityAverage}
                        minRange={0}
                        maxRange={100} //{setMaxRange("average")}
                        unitText="%"
                        setParentState={setParentState}
                        typeVariety="main"
                        nameVariety="QualityMedium"
                        qualityName="average"
                      />
                    </div>

                    <div className="quality-input-container">
                      <span>Poor</span>
                      <NumericInput
                        key={3}
                        defaultValue={qualityPoor}
                        minRange={0}
                        maxRange={100} //{setMaxRange("poor")}
                        unitText="%"
                        setParentState={setParentState}
                        typeVariety="main"
                        nameVariety="QualityLow"
                        qualityName="poor"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

export default QualityValueSlider;
