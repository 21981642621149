import "./history.style.css";

import React, { Component } from "react";
import moment from "moment";

import {
  DatePicker,
  Spin,
  Select,
  Table,
  Modal,
  Icon,
  Button,
  message,
  Progress,
} from "antd";

import {
  selectUserData,
  selectAllBranches,
} from "../../redux/user/user.selectors";

import API, { baseURL } from "../../api";

import Axios from "axios";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setAuditModalVisibility } from "../../redux/summary/summary.actions";
import { selectIsModalVisible } from "../../redux/summary/summary.selectors";

import { setSummaryVisibility } from "../../redux/arrival-entry/arrival-entry.actions";

import CustomSelectSummary from "../../components/custom-select-summary/custom-select-summary.component";
const FileDownLoad = require("js-file-download");

const { RangePicker } = DatePicker;
const { Option } = Select;

//#region History Table Props
const tableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  scroll: { x: 200, y: window.innerHeight - 305 },
};
//#region Modal Table Props
const modalTableProps = {
  pagination: false,
  size: "default",
  bordered: true,
  scroll: { x: 150, y: 250 },
};

/**
 * @component
 * @description Reusable component to display table header.
 * @property {string} title Header title
 * @property {string} subtitle Header subtitle
 * @property {string} value Header value
 * @memberof History
 */
const TableNewHeader = (props) => {
  const { title, subtitle, value } = props;
  return (
    <div className="col-container">
      <div style={{ color: "#bad2f8", fontSize: "12px", fontWeight: "normal" }}>
        {title}
      </div>
      <div className="bottom-container">
        <h1>{value}</h1>
        <div
          style={{
            color: "#bad2f8",
            fontSize: "12px",
            fontWeight: "normal",
            marginTop: "0px",
          }}
        >
          {subtitle}
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @description History page container in Buying Decision page.
 * @component
 * @extends {Component}
 */
class History extends Component {
  state = {
    selectedVariety: "",
    selectedBranch: "",
    dropdownData: {
      branches: [],
      hubs: [],
      varieties: [],
    },
    selectedBranchVal: "",
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    isSpinnerOn: true,
    isSpinnerOnProgress: false,
    spinnerOnModal: false,
    ProgressClicked: false,
    selectedDivisions: [],
    selectedHubs: [],
    selectedClaimType: [],
    selectedVarieties: [],

    modalVisible: false,
    tableData: [],
    modalTableData: [],
  };

  /**
   * @function
   * @description Retieve and store history data from API.
   * @param {string[]} varietyIds Variety IDs
   * @param {string} branchId Branch ID
   * @param {string[]} hubIds Hub IDs
   * @param {string[]} claimTypes Mandi IDs
   * @param {string[]} divisions division IDs
   * @param {string} startDate Start date of date range
   * @param {string} endDate End date of date range
   * @memberof History
   */
  setDataForTable = (
    varietyIds,
    branchId,
    hubIds,
    claimTypes,
    divisions,
    startDate,
    endDate
  ) => {
    const tableParams = {
      hub_id: hubIds,
      variety_id: varietyIds,
      division: divisions.length === 3 ? null : divisions,
      claim_type: claimTypes.length === 2 ? null : claimTypes,
      branch_id: branchId,
      start_date: startDate,
      end_date: endDate,
    };
    this.setState((prevState) => ({ ...prevState, isSpinnerOn: true }));

    API.get(`/decision/history`, { params: tableParams })
      .then((response) => {
        console.log(
          "The Response: ",
          response.data.data.buying_decisions_history
        );
        let count = 0;
        const data =
          response.data.data.buying_decisions_history &&
          response.data.data.buying_decisions_history.map((item) => {
            return {
              key: count++,
              buyingDecisionId: item.bd_id,
              dateTime: moment(item.created_time).format("DD-MM-YYYY hh:mm a"),
              hub: item.hub_name,
              variety: item.variety_name,
              division: item.division,
              claimType: item.claim_type,
              purchasePrice: item.prev_day_purchase_price,
              purchaseQty: item.prev_day_purchase_qty,
              min: item.revised_min_price,
              max: item.revised_max_price,
              revisedQty: item.revised_qty,
              modalPrice: item.tdy_modal_price,
              createdBy: item.created_by,
              arrivalQty: item.tdy_arrival_qty,
              remarks: item.remark,
              level1: item.approver1,
              level2: item.approver2,
            };
          });
        this.setState((prevState) => ({
          ...prevState,
          tableData: data,
          isSpinnerOn: false,
        }));
      })
      .catch((err) => {
        this.setState((prevState) => ({ ...prevState, isSpinnerOn: false }));
      });
  };

  /**
   *
   * @function
   * @description Retieve and store table data for trails in modal from API.
   * @param {string} decisionId
   * @memberof History
   */
  GetModalTableData(decisionId) {
    this.setState({ modalTableData: [], spinnerOnModal: true });

    API.get(`/decision/history/${decisionId}`)
      .then((response) => {
        let count = 0;
        const data =
          response.data.data.buying_decisions_audits &&
          response.data.data.buying_decisions_audits.map((item) => {
            return {
              key: count++,
              buyingDecisionId: item.bd_id,
              dateTime: moment(item.modified_time).format("DD-MM-YYYY hh:mm a"),
              hub: item.hub_name,
              variety: item.variety_name,
              division: item.division,
              claimType: item.claim_type,
              purchasePrice: item.prev_day_purchase_price,
              purchaseQty: item.prev_day_purchase_qty,
              min: item.revised_min_price,
              max: item.revised_max_price,
              revisedQty: item.revised_qty,
              modalPrice: item.tdy_modal_price,
              createdBy: item.created_by,
              arrivalQty: item.tdy_arrival_qty,
              remarks: item.remark,
              level1: item.approver1,
              level2: item.approver2,
              version: item.version,
            };
          });

        this.setState({
          modalTableData: data,
          spinnerOnModal: false,
        });
      })
      .catch((err) => {
        this.setState((prevState) => ({ ...prevState, spinnerOnModal: false }));
      });
  }
  //#endregion

  /**
   * @function
   * @description Handle date filter selection.
   * @param {dateRange} dates
   * @param {string[]} dateStrings Array of strings consisting of selected date range.
   * @memberof History
   */
  onChange = (dates, dateStrings) => {
    this.setState(
      { startDate: dateStrings[0], endDate: dateStrings[1] },
      () => {
        this.setDataForTable(
          this.state.selectedVarieties,
          this.state.selectedBranch,
          this.state.selectedHubs,
          this.state.selectedClaimType,
          this.state.selectedDivisions,
          this.state.startDate,
          this.state.endDate
        );
      }
    );
  };

  /**
   * @function
   * @description Generic function to set hubs in filter
   * @param {string} branchId
   * @memberof History
   */
  setHubs = (branchId) => {
    const params = {
      table_type: "reports",
      branch_id: branchId,
      mapping: "primary",
    };
    API.get("/hubs", {
      params: params,
    }).then((response) => {
      this.setState((prevState) => ({
        ...prevState,
        dropdownData: {
          ...prevState.dropdownData,
          hubs: response.data.data.hubs,
        },
        selectedHubs: [],
      }));
    });
  };

  componentDidMount() {
    const params = {
      table_type: "reports",
    };
    API.get("/branches", { params: params }).then((response) => {
      const { user } = this.props;
      const branches = response.data.data.branches;
      let branch = [];
      for (var i = 0; i < user.userTerritories.length; i++) {
        for (var j = 0; j < branches.length; j++) {
          if (user.userTerritories[i] === branches[j].territory_id) {
            branch.push(branches[j]);
          }
        }
      }
      API.get("/varieties").then((varietyResponse) => {
        this.setState(
          {
            dropdownData: {
              branches: branch,
              claimType: null,
              hubs: null,
              divisions: null,
              varieties: varietyResponse.data.data,
            },
            selectedBranch: branch[0].territory_id,
            selectedBranchVal: branch[0].territory_name,
            isSpinnerOnTable: false,
          },
          () => {
            this.setHubs(this.state.selectedBranch);

            this.setDataForTable(
              this.state.selectedVarieties,
              this.state.selectedBranch,
              this.state.selectedHubs,
              this.state.selectedClaimType,
              this.state.selectedDivisions,
              this.state.startDate,
              this.state.endDate
            );
          }
        );
      });
    });
  }

  //API header

  render() {
    //multiple variety handling
    //#region
    //filtering variety data to name and key
    let varietyIds, varietyNames;
    if (this.state.dropdownData.varieties) {
      varietyIds = this.state.dropdownData.varieties
        ? this.state.dropdownData.varieties.map((item) => item.variety_id)
        : "";
      varietyNames = this.state.dropdownData.varieties.map(
        (item) => item.variety_name
      );
    }

    /**
     *
     * @description Handle variety filter selection.
     * @param {string} value Selected variety value.
     * @param {object} props Selected variety object (key and value).
     * @memberof History
     */
    const handleMultipleVarietySelect = (value, props) => {
      this.setState((prevState) => ({
        ...prevState,
        selectedDivisions: [],
        selectedVarieties: value,
      }));
    };
    //#endregion

    //branch handling
    //#region
    //filtering hub data to name and key
    let branchId, branchName;
    if (this.state.dropdownData.branches) {
      branchId = this.state.dropdownData.branches
        ? this.state.dropdownData.branches.map((item) => item.territory_id)
        : "";
      branchName = this.state.dropdownData.branches.map(
        (item) => item.territory_name
      );
    }

    /**
     *
     * @description Handle branch filter selection.
     * @param {string} value Selected branch value.
     * @param {object} props Selected branch object (key and value).
     * @memberof History
     */
    const handleBranchSelect = (value, props) => {
      this.setState({ isSpinnerOn: true, selectedHubs: [] });

      API.get(
        `/hubs?table_type=transaction&mapping=primary&branch_id=${props.key}`
      ).then((response) => {
        this.setState(
          (prevState) => ({
            ...prevState,
            selectedBranch: value === "All" ? "" : props.key,
            dropdownData: {
              ...prevState.dropdownData,
              hubs: value === "All" ? [] : response.data.data.hubs,
              divisions: null,
            },
            selectedBranchVal: value,
            // selectedRegionVal: this.state.defaultRegion,
            // selectedHubVal: this.state.defaultHub,
            // selectedMandiVal: this.state.defaultMandi,
          }),
          () =>
            this.setDataForTable(
              this.state.selectedVarieties,
              this.state.selectedBranch,
              this.state.selectedHubs,
              this.state.selectedClaimType,
              this.state.selectedDivisions,
              this.state.startDate,
              this.state.endDate
            )
        );
      });
    };
    //#endregion

    //multiple hub handling
    //#region
    //filtering hub data to name and key
    let hubIds, hubNames;
    if (this.state.dropdownData.hubs) {
      hubIds = this.state.dropdownData.hubs
        ? this.state.dropdownData.hubs.map((item) => item.territory_id)
        : "";
      hubNames = this.state.dropdownData.hubs.map(
        (item) => item.territory_name
      );
    }

    /**
     *
     * @description Handle hub filter selection.
     * @param {string} value Selected hub value.
     * @param {object} props Selected hub object (key and value).
     * @memberof History
     */
    const handleMultipleHubSelect = (value, props) => {
      this.setState((prevState) => ({
        ...prevState,
        selectedHubs: value.length <= 4 ? value : this.state.selectedHubs,
      }));
    };
    //#endregion

    //multiple claim type handling
    //#region
    //filtering hub data to name and key

    /**
     *
     * @description Handle multiple claim type filter selection.
     * @param {string} value Selected claim value.
     * @param {object} props Selected claim object (key and value).
     * @memberof History
     */
    const handleMultipleClaimSelect = (value, props) => {
      this.setState((prevState) => ({
        ...prevState,
        selectedClaimType: value,
        selectedDivisions: [],
      }));
    };
    //#endregion

    //multiple Division handling
    //#region

    /**
     *
     * @description Handle multiple division filter selection.
     * @param {string} value Selected division value.
     * @param {object} props Selected division object (key and value).
     * @memberof History
     */
    const handleMultipleDivisionSelect = (value, props) => {
      this.setState((prevState) => ({
        ...prevState,
        selectedDivisions: value,
      }));
    };
    //#endregion

    //To compare with selected dropdowns
    let hubs = [],
      varieties = [],
      claimType = [],
      divisions = [];

    return (
      <div
        className="history-report-container"
        style={{ height: window.innerHeight - 131 }}
      >
        <Spin spinning={this.state.isSpinnerOn} tip="Loading...">
          <Spin
            spinning={this.state.isSpinnerOnProgress}
            tip="Please wait while the file is being Downloaded..."
          >
            <div className="summary">
              <div
                className="filter-container"
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div
                  className="summary-filters"
                  style={{
                    width: "1257px",
                  }}
                >
                  <div className="filter-select">
                    <div className="filter-select-title">Date Range</div>
                    <RangePicker
                      defaultValue={[moment(), moment()]}
                      ranges={{
                        "Past 7 days": [moment().subtract(7, "days"), moment()],
                        "This Month": [moment().startOf("month"), moment()],
                        "Last Month": [
                          moment()
                            .startOf("month")
                            .subtract(1, "days")
                            .startOf("month"),
                          moment().startOf("month").subtract(1, "days"),
                        ],
                        "Past 30 days": [
                          moment().subtract(30, "days"),
                          moment(),
                        ],
                      }}
                      onChange={this.onChange}
                    />
                  </div>

                  <div
                    className="filter-select"
                    style={{
                      height: "57px",
                    }}
                  >
                    <div className="filter-select-title">Varieties</div>
                    <div className="multiple-select">
                      <Select
                        maxTagCount={2}
                        showArrow
                        showSearch
                        disabled={
                          this.state.isSpinnerOn ||
                          this.state.dropdownData.varieties == null
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        mode="multiple"
                        placeholder="Select varieties"
                        value={this.state.selectedVarieties}
                        onChange={(value, props) =>
                          handleMultipleVarietySelect(value, props)
                        }
                        onFocus={() => {
                          varieties = this.state.selectedVarieties;
                        }}
                        onBlur={(value) => {
                          this.setDataForTable(
                            this.state.selectedVarieties,
                            this.state.selectedBranch,
                            this.state.selectedHubs,
                            this.state.selectedClaimType,
                            this.state.selectedDivisions,
                            this.state.startDate,
                            this.state.endDate
                          );
                        }}
                      >
                        {this.state.dropdownData.varieties !== null &&
                          varietyNames.map((item, idx) => (
                            <Option
                              value={varietyIds ? varietyIds[idx] : idx}
                              key={varietyIds ? varietyIds[idx] : idx}
                            >
                              {item}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </div>

                  <div className="filter-select">
                    <div className="filter-select-title">Branch</div>
                    <CustomSelectSummary
                      placeholder={
                        this.state.selectedBranchVal === ""
                          ? "Select Branch"
                          : this.state.selectedBranchVal
                      }
                      disabled={false}
                      list={branchName}
                      keyList={branchId}
                      handleSelect={handleBranchSelect}
                    />
                  </div>
                  <div
                    className="filter-select"
                    style={{
                      height: "57px",
                    }}
                  >
                    <div className="filter-select-title">Hubs</div>
                    <div className="multiple-select">
                      <Select
                        maxTagCount={2}
                        showArrow
                        showSearch
                        disabled={
                          this.state.isSpinnerOn ||
                          this.state.dropdownData.hubs == null
                        }
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        mode="multiple"
                        placeholder="Select hubs"
                        value={this.state.selectedHubs}
                        onChange={(value, props) =>
                          handleMultipleHubSelect(value, props)
                        }
                        onFocus={() => {
                          hubs = this.state.selectedHubs;
                        }}
                        onBlur={(value) => {
                          this.setDataForTable(
                            this.state.selectedVarieties,
                            this.state.selectedBranch,
                            this.state.selectedHubs,
                            this.state.selectedClaimType,
                            this.state.selectedDivisions,
                            this.state.startDate,
                            this.state.endDate
                          );
                        }}
                      >
                        {this.state.dropdownData.hubs !== null &&
                          hubNames.map((item, idx) => (
                            <Option
                              value={hubIds ? hubIds[idx] : idx}
                              key={hubIds ? hubIds[idx] : idx}
                            >
                              {item}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </div>

                  <div
                    className="filter-select"
                    style={{
                      height: "57px",
                    }}
                  >
                    <div className="filter-select-title">Claim Type</div>
                    <div className="multiple-select">
                      <Select
                        maxTagCount={2}
                        showArrow
                        showSearch
                        disabled={false}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        mode="multiple"
                        placeholder="Select Claim Type"
                        value={this.state.selectedClaimType}
                        onChange={(value, props) =>
                          handleMultipleClaimSelect(value, props)
                        }
                        onFocus={() => {
                          claimType = this.state.selectedClaimType;
                        }}
                        onBlur={(value) => {
                          this.setDataForTable(
                            this.state.selectedVarieties,
                            this.state.selectedBranch,
                            this.state.selectedHubs,
                            this.state.selectedClaimType,
                            this.state.selectedDivisions,
                            this.state.startDate,
                            this.state.endDate
                          );
                        }}
                      >
                        <Option value={1} key={1}>
                          Claim
                        </Option>
                        <Option value={2} key={2}>
                          No Claim
                        </Option>
                      </Select>
                    </div>
                  </div>
                  <div
                    className="filter-select"
                    style={{
                      height: "57px",
                    }}
                  >
                    <div className="filter-select-title">Divisions</div>
                    <div className="multiple-select">
                      <Select
                        maxTagCount={2}
                        showArrow
                        showSearch
                        disabled={false}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        mode="multiple"
                        placeholder="Select Divisions"
                        value={this.state.selectedDivisions}
                        onChange={(value, props) =>
                          handleMultipleDivisionSelect(value, props)
                        }
                        onFocus={() => {
                          divisions = this.state.selectedDivisions;
                        }}
                        onBlur={(value) => {
                          this.setDataForTable(
                            this.state.selectedVarieties,
                            this.state.selectedBranch,
                            this.state.selectedHubs,
                            this.state.selectedClaimType,
                            this.state.selectedDivisions,
                            this.state.startDate,
                            this.state.endDate
                          );
                        }}
                      >
                        <Option value={1} key={1}>
                          ABD
                        </Option>
                        <Option value={2} key={2}>
                          FBD
                        </Option>
                        <Option value={3} key={3}>
                          NCDEX
                        </Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="history-download-container">
              <div className="history-download">
                <Icon type="download" className="d-icon" />
                <div className="dwnld">
                  Download
                  <Button
                    onClick={() => {
                      this.setState({ isSpinnerOnProgress: true });
                      this.setState({ downloaddata: 0 });
                      this.setState({ ProgressClicked: true });
                      const downloadParams = {
                        download: true,
                        download_type: "latest",
                        branch_id: this.state.selectedBranch,
                        hub_id: this.state.selectedHubs,
                        variety_id: this.state.selectedVarieties,
                        division: this.state.selectedDivisions,
                        claim_type: this.state.selectedClaimType,
                        start_date: this.state.startDate,
                        end_date: this.state.endDate,
                      };

                      // API.get(`/decision/history`, {

                      //   params: downloadParams,
                      // })
                      //   .then((fileresponse) => {
                      //     if (fileresponse.data.status) {

                      //#region file download
                      Axios({
                        url: `${baseURL}/decision/history`,
                        method: "GET",
                        params: downloadParams,
                        responseType: "blob",
                        onDownloadProgress: (progressEvent) => {
                          let progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                          );
                          this.setState({ downloaddata: progress });
                        },
                      })
                        .then((response) => {
                          if (response.status !== 204) {
                            FileDownLoad(
                              response.data,
                              `Decision_History_Report_${new Date().toLocaleDateString()}.pdf`
                            );
                            this.setState({ isSpinnerOnProgress: false });
                            setTimeout(
                              function () {
                                this.setState({ ProgressClicked: false });
                              }.bind(this),
                              5000
                            );
                          } else {
                            message.warning("File has no data.");
                            this.setState({
                              isSpinnerOnProgress: false,
                              ProgressClicked: false,
                            });
                          }
                        })
                        .catch((err) => {
                          message.error("File cannot be downloaded");
                          this.setState({
                            isSpinnerOnProgress: false,
                            ProgressClicked: false,
                          });
                        });
                      //#endregion
                      //   } else {
                      //     message.warning("File has no data");
                      //     this.setState({ isSpinnerOnProgress: false, ProgressClicked:false});
                      //   }
                      // })
                      // .catch((err) => {
                      //   message.error("Error downloading file");
                      //   this.setState({ isSpinnerOnProgress: false, ProgressClicked:false});
                      // });
                    }}
                  >
                    Latest
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ isSpinnerOnProgress: true });
                      this.setState({ downloaddata: 0 });
                      this.setState({ ProgressClicked: true });
                      const downloadParams = {
                        download: true,
                        download_type: "all",
                        branch_id: this.state.selectedBranch,
                        hub_id: this.state.selectedHubs,
                        variety_id: this.state.selectedVarieties,
                        division: this.state.selectedDivisions,
                        claim_type: this.state.selectedClaimType,
                        start_date: this.state.startDate,
                        end_date: this.state.endDate,
                      };

                      // API.get(`/decision/history`, {
                      //   params: downloadParams,
                      // })
                      //   .then((fileresponse) => {
                      //     if (fileresponse.data.status) {

                      //#region file download
                      Axios({
                        url: `${baseURL}/decision/history`,
                        method: "GET",
                        params: downloadParams,
                        responseType: "blob",
                        onDownloadProgress: (progressEvent) => {
                          let progress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                          );
                          this.setState({ downloaddata: progress });
                        },
                      })
                        .then((response) => {
                          if (response.status !== 204) {
                            FileDownLoad(
                              response.data,
                              `Decision_History_Report_${new Date().toLocaleDateString()}.pdf`
                            );
                            this.setState({ isSpinnerOnProgress: false });
                            setTimeout(
                              function () {
                                this.setState({ ProgressClicked: false });
                              }.bind(this),
                              5000
                            );
                          } else {
                            message.warning("File has no data.");
                            this.setState({
                              isSpinnerOnProgress: false,
                              ProgressClicked: false,
                            });
                          }
                        })
                        .catch((err) => {
                          message.error("File cannot be downloaded");
                          this.setState({
                            isSpinnerOnProgress: false,
                            ProgressClicked: false,
                          });
                        });
                      //#endregion
                      //   } else {
                      //     message.warning("File has no data");
                      //     this.setState({ isSpinnerOnProgress: false, ProgressClicked:false });
                      //   }
                      // })
                      // .catch((err) => {
                      //   message.error("Error downloading file");
                      //   this.setState({ isSpinnerOnProgress: false, ProgressClicked:false });
                      // });
                    }}
                  >
                    All
                  </Button>
                </div>
              </div>
              <div className="progress-download-container">
                {this.state.ProgressClicked && (
                  <Progress
                    type="circle"
                    percent={this.state.downloaddata}
                    width={37}
                    strokeColor={{ "0%": "#eff3f6", "100%": "#87d068" }}
                    trailColor="#003285"
                  />
                )}
              </div>
            </div>
            {/* <Spin spinning={this.state.isSpinnerOn} tip="Loading..."> */}
            <div className="history-table-container">
              <Table
                //   loading={spinnerOn}
                pagination={false}
                columns={[
                  {
                    title: "Date Time",
                    key: "dateTime",
                    dataIndex: "dateTime",
                    children: [
                      {
                        dataIndex: "dateTime",
                      },
                    ],
                  },
                  {
                    title: "Hub",
                    key: "hub",
                    dataIndex: "hub",
                    children: [
                      {
                        dataIndex: "hub",
                      },
                    ],
                  },
                  {
                    title: "Variety",
                    key: "variety",
                    dataIndex: "variety",
                    children: [
                      {
                        dataIndex: "variety",
                      },
                    ],
                  },
                  // {
                  //   title: "Division",
                  //   key: "division",
                  //   dataIndex: "division",
                  //   children: [
                  //     {
                  //       dataIndex: "division",
                  //     },
                  //   ],
                  // },
                  {
                    title: "Claim Type",
                    key: "claimType",
                    dataIndex: "claimType",
                    children: [
                      {
                        dataIndex: "claimType",
                      },
                    ],
                  },
                  {
                    title: "Previous Day",
                    key: "prevDay",
                    children: [
                      {
                        title: (
                          <TableNewHeader
                            title="Purchase Price"
                            subtitle="₹/MT"
                          />
                        ),
                        key: "purchasePrice",
                        dataIndex: "purchasePrice",
                        width: 95,
                      },
                      {
                        title: (
                          <TableNewHeader title="Qty Bought" subtitle="MT" />
                        ),
                        key: "purchaseQty",
                        dataIndex: "purchaseQty",
                      },
                    ],
                  },
                  {
                    title: "Revised",
                    key: "revised",
                    children: [
                      {
                        title: <TableNewHeader title="Min" subtitle="₹/MT" />,
                        key: "min",
                        dataIndex: "min",
                        width: 70,
                      },
                      {
                        title: <TableNewHeader title="Max" subtitle="₹/MT" />,
                        key: "max",
                        dataIndex: "max",
                        width: 70,
                      },
                      {
                        title: <TableNewHeader title="Qty" subtitle="MT" />,
                        key: "revisedQty",
                        dataIndex: "revisedQty",
                        width: 70,
                      },
                    ],
                  },
                  {
                    title: "Arrivals",
                    key: "arrivals",
                    children: [
                      {
                        title: (
                          <TableNewHeader title="Modal Price" subtitle="₹/MT" />
                        ),
                        key: "modalPrice",
                        dataIndex: "modalPrice",
                      },
                      {
                        title: <TableNewHeader title="Qty" subtitle="MT" />,
                        key: "arrivalQty",
                        dataIndex: "arrivalQty",
                        width: 70,
                      },
                    ],
                  },
                  {
                    title: "Remarks",
                    key: "remarks",
                    dataIndex: "remarks",
                    children: [
                      {
                        dataIndex: "remarks",
                      },
                    ],
                  },
                  {
                    title: "Approvals",
                    key: "approvals",
                    children: [
                      {
                        title: <TableNewHeader title="Level 1" />,
                        key: "level1",
                        dataIndex: "level1",
                      },
                      {
                        title: <TableNewHeader title="Level 2" />,
                        key: "level2",
                        dataIndex: "level2",
                      },
                    ],
                  },
                  {
                    title: "Created By",
                    key: "createdBy",
                    children: [
                      {
                        dataIndex: "createdBy",
                      },
                    ],
                  },
                  {
                    title: "Trails",
                    key: "trails",
                    dataIndex: "trails",

                    children: [
                      {
                        dataIndex: "trails",
                        render: (val, rowRecord) => (
                          <div
                            className="trails-table-component"
                            onClick={() => {
                              this.GetModalTableData(
                                rowRecord.buyingDecisionId
                              );
                              this.setState({ modalVisible: true });
                            }}
                          >
                            View Trails
                          </div>
                        ),
                      },
                    ],
                  },
                ]}
                dataSource={this.state.tableData}
                {...tableProps}
              />
            </div>
            {/* </Spin> */}
            <Modal
              key="4"
              wrapClassName="history"
              keyboard
              width="1300px"
              title={`Revisions`}
              centered
              onCancel={() => this.setState({ modalVisible: false })}
              footer={null}
              visible={this.state.modalVisible}
            >
              <div className="history-table-container">
                {/* <Spin spinning={this.state.spinnerOnModal}> */}
                <Table
                  loading={this.state.spinnerOnModal}
                  pagination={false}
                  columns={[
                    {
                      title: "Date Time",
                      key: "dateTime",
                      dataIndex: "dateTime",
                      children: [
                        {
                          dataIndex: "dateTime",
                          width: 80,
                        },
                      ],
                    },
                    {
                      title: "Hub",
                      key: "hub",
                      dataIndex: "hub",
                      children: [
                        {
                          dataIndex: "hub",
                        },
                      ],
                    },
                    {
                      title: "Variety",
                      key: "variety",
                      dataIndex: "variety",
                      children: [
                        {
                          dataIndex: "variety",
                        },
                      ],
                    },
                    // {
                    //   title: "Division",
                    //   key: "division",
                    //   dataIndex: "division",
                    //   children: [
                    //     {
                    //       dataIndex: "division",
                    //     },
                    //   ],
                    // },
                    {
                      title: "Claim Type",
                      key: "claimType",
                      dataIndex: "claimType",
                      children: [
                        {
                          dataIndex: "claimType",
                        },
                      ],
                    },
                    {
                      title: "Previous Day",
                      key: "prevDay",
                      children: [
                        {
                          title: (
                            <TableNewHeader
                              title="Purchase Price"
                              subtitle="₹/MT"
                            />
                          ),
                          key: "purchasePrice",
                          dataIndex: "purchasePrice",
                        },
                        {
                          title: (
                            <TableNewHeader title="Qty Bought" subtitle="MT" />
                          ),
                          key: "purchaseQty",
                          dataIndex: "purchaseQty",
                        },
                      ],
                    },
                    {
                      title: "Revised",
                      key: "revised",
                      children: [
                        {
                          title: <TableNewHeader title="Min" subtitle="₹/MT" />,
                          key: "min",
                          dataIndex: "min",
                          width: 70,
                        },
                        {
                          title: <TableNewHeader title="Max" subtitle="₹/MT" />,
                          key: "max",
                          dataIndex: "max",
                          width: 70,
                        },
                        {
                          title: <TableNewHeader title="Qty" subtitle="MT" />,
                          key: "revisedQty",
                          dataIndex: "revisedQty",
                          width: 70,
                        },
                      ],
                    },
                    {
                      title: "Arrivals",
                      key: "arrivals",
                      children: [
                        {
                          title: (
                            <TableNewHeader
                              title="Modal Price"
                              subtitle="₹/MT"
                            />
                          ),
                          key: "modalPrice",
                          dataIndex: "modalPrice",
                        },
                        {
                          title: <TableNewHeader title="Qty" subtitle="MT" />,
                          key: "arrivalQty",
                          dataIndex: "arrivalQty",
                          width: 70,
                        },
                      ],
                    },
                    {
                      title: "Remarks",
                      key: "remarks",
                      dataIndex: "remarks",
                      children: [
                        {
                          dataIndex: "remarks",
                        },
                      ],
                    },
                    {
                      title: "Approvals",
                      key: "approvals",
                      children: [
                        {
                          title: <TableNewHeader title="Level 1" />,
                          key: "level1",
                          dataIndex: "level1",
                        },
                        {
                          title: <TableNewHeader title="Level 2" />,
                          key: "level2",
                          dataIndex: "level2",
                        },
                      ],
                    },
                    {
                      title: "Created By",
                      key: "createdBy",
                      children: [
                        {
                          dataIndex: "createdBy",
                        },
                      ],
                    },
                    {
                      title: "Version",
                      key: "version",
                      children: [
                        {
                          dataIndex: "version",
                        },
                      ],
                    },
                  ]}
                  dataSource={this.state.modalTableData}
                  {...modalTableProps}
                />
                {/* </Spin> */}
              </div>
            </Modal>
          </Spin>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isModalVisible: selectIsModalVisible,
  branches: selectAllBranches,
  user: selectUserData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setAuditModalVisibility: (visibilityState) =>
      dispatch(setAuditModalVisibility(visibilityState)),
    setSummaryVisibility: (isEnabled) =>
      dispatch(setSummaryVisibility(isEnabled)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
